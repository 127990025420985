import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

import { useAuth } from "../context/auth";

function PrivateRoute({ component: Component, ...rest }) {
  const data = useAuth();
  console.log("PrivateRoute", data);
  return (
    <Route
      {...rest}
      render={(props) =>
        data.userObject && data.userObject.email ? (
          <Component {...props}></Component>
        ) : (
          <Redirect to="/user/me/login"></Redirect>
        )
      }
    ></Route>
  );
}
export default PrivateRoute;
