/*************************************
 **************Import React core
 **************************************/
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
/*************************************
 **************Import JS CODE
 **************************************/
import { ReactComponent as Loader } from "../../style/resource/loaderTreePoint.svg";
// import "../../style/login/login-styles.css";
import { useAuth } from "../../context/auth";
import { isValidEmail } from "../../utils/index";
import logo from "../../../src/style/resource/logo.jpeg";
import { relanceData } from "../../service/backEndService";
import { useTranslation } from "react-i18next";

export default function Valid_email() {
  const { userObject, setUserObject, changeLanguage, langue, data } = useAuth();
  const { t, i18n } = useTranslation();

  const relanceEmail = async () => {
    let ress = await relanceData(userObject.email);

    console.log("relanceEmail", ress);
  };
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          height: "25vh",
          marginTop: "10vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logo} className="icon-rounded-logo"></img>
      </div>
      <div
        style={{
          display: "flex",
          flex: 4,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            fontFamily: "Proxima Nova",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {t("register.txt_value")}
        </span>
        <span
          style={{
            fontFamily: "Proxima Nova",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {t("register.txt_value2")}
        </span>
        <span
          style={{
            fontFamily: "Proxima Nova",
            fontWeight: "bold",
            fontSize: "15px",
            marginTop: "10vh",
          }}
        >
          {t("register.txt_value3")}

          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => relanceEmail()}
          >
            {""}
            {t("register.txt_value4")}
          </span>
        </span>
      </div>
    </div>
  );
}
