import { postAdataListoorRdv, getAdata, putAdata } from "./api";
import { endpoint, baseUrl } from "./endpoints";

export const checkEmail = async (email) => {
  return postAdataListoorRdv(baseUrl + endpoint.check_email, {email: email});
};

export const create_User = async (data) => {
  return postAdataListoorRdv(baseUrl + endpoint.create_user, data);
};

export const login = async (data) => {
  return postAdataListoorRdv(baseUrl + endpoint.check_user_credantial, data);
};

export const valid_url_token = async (data) => {
  return postAdataListoorRdv(baseUrl + endpoint.valid_token, data);
};

export const create_event = async (data) => {
  return postAdataListoorRdv(baseUrl + endpoint.create_event, data);
};

export const create_event_groupe = async (data) => {
  return postAdataListoorRdv(baseUrl + endpoint.create_event_goupe, data);
};

export const post_disponibilityes = async (data) => {
  return postAdataListoorRdv(baseUrl + endpoint.diponibilitys, data);
};

export const getMyeventList = (id_user) => {
  return getAdata(baseUrl + "api/events?user_id=" + id_user);
};

export const getMyeventList_inLink = (id_user) => {
  return getAdata(baseUrl + endpoint.get_list_event_info_link + "?" + "user_link=" + id_user);
};

export const relanceData = (email) => {
  return getAdata(baseUrl + endpoint.relance_email + "?email=" + email);
};

export const modif_disponibility = (data) => {
  return putAdata(baseUrl + endpoint.diponibilitys, data);
};

export const put_event = (data) => {
  return putAdata(baseUrl + endpoint.put_an_event, data);
};

export const getAvaibility = (user_link, link) => {
  return getAdata(baseUrl + endpoint.diponibilitys + "?user_link=" + user_link + "&event_link=" + link);
};

export const getDispo60 = (event_id) => {
  return getAdata(baseUrl + endpoint.diponibilitys + "?event_id=" + event_id);
};

export const subscribeInEvent = (data) => {
  return postAdataListoorRdv(baseUrl + endpoint.subscribe, data);
};

export const getAllUserSubscribe = (event_id, date) => {
  return getAdata(baseUrl + endpoint.getAllsubscribe + "?event_id=" + event_id + "&date=" + date);
};

export const getMyEventAvenir = (user_id) => {
  return getAdata(baseUrl + endpoint.upcomming + "?user_id=" + user_id);
};

export const getMyEventCurrent = (email) => {
  return getAdata(baseUrl + endpoint.event_current + "?user_email=" + email);
};
