/*************************************
 **************Import React core
 **************************************/
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
/*************************************
 **************Import JS CODE
 **************************************/
import { ReactComponent as Loader } from "../../style/resource/loaderTreePoint.svg";
import "../../style/login/login-styles.css";
import logo from "../../../src/style/resource/logo.jpeg";
import { useAuth } from "../../context/auth";
import { isValidEmail } from "../../utils/index";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import "moment/locale/zh-cn";
import "moment/locale/en-gb";
import Header from "./../_common/header";
import { useTranslation } from "react-i18next";
import { checkEmail, login } from "../../service/backEndService";
import eventHandler from "../../hooks/event_handler";

export default function Login(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { setUserObject, changeLanguage, langue, data } = useAuth();
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);

  const [password, setPassword] = useState("");
  const [showError_password, setShowErrorPassword] = useState(false);
  const [showError_accout_active, setShowError_accout_active] = useState(false);

  const [errorEmail, setErrorEmail] = useState(false);
  const [stateOfLoader, setStateOfLoader] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);

  function handleEmail(evt) {
    setShowError(false);
    setErrorEmail(false);

    if (evt.target) {
      setEmail(evt.target.value.trim());
    }
  }

  function handlePassword(evt) {
    setShowErrorPassword(false);
    if (evt.target) {
      setPassword(evt.target.value.trim());
    }
  }

  async function navToFormPassword() {
    if (isValidEmail(email)) {
      setStateOfLoader(true);
      let data = {
        email,
        password,
      };
      // let res = await checkEmail(email);
      // console.log("navToAccueil", res);
      // if (res && res.data.success) {
      //   setUserObject({ email: email, name: "jose", firstName: "tt" });
      //   history.push({
      //     pathname: "/user/me/form_password",
      //     state: {
      //       email,
      //     },
      //   });
      // } else {
      //   setShowError(true);
      //   setStateOfLoader(false);
      // }
      let res = await login(data);
      console.log("setUserObjectsetUserObject", res);
      if (res.data && res.data.success) {
        setUserObject(res.data.data);
        localStorage.setItem("user_log", "OK");
        history.push("/user/me/accueil");
      } else if (
        // res.data.success == false &&
        res.data.message == "Acount desabled"
      ) {
        console.log("AATO");
        setShowError_accout_active(true);
        setStateOfLoader(false);

        // history.push("/user/me/valid_email");
      } else {
        console.log("AATOqsqs");
        setShowErrorPassword(true);
        setStateOfLoader(false);
      }
    } else {
      setStateOfLoader(false);
      setErrorEmail(true);
      console.log("TELO");
    }
  }

  function navigateTocreateAcount() {
    history.push("/user/me/register-form-mail");
  }

  const keyEnter = ({ key }) => {
    console.log("Evt", key);
    if (key == "Enter") {
      navToFormPassword();
    } else return;
  };

  eventHandler("keydown", keyEnter);

  return (
    <>
      <Header />
      <div
        className="container-fluid"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="container-header">
          <img src={logo} className="icon-rounded-logo"></img>
          <p className="title-login">{t("login.connect")}</p>
        </div>

        <div className="container-form">
          <span className="txt-mail-info-login">{t("login.input_email")}</span>
          <div
            className="container-email-login"
            style={{ backgroundColor: showError ? "#FCE6E7" : "white" }}
          >
            <input
              type="text"
              className="txt-email"
              style={{ backgroundColor: showError ? "#FCE6E7" : "white" }}
              placeholder="adresse email"
              id="0.12504859747231034"
              name="email"
              onChange={handleEmail}
              value={email}
            />
          </div>

          <span
            style={{
              alignSelf: "flex-start",
              marginLeft: "5vw",
              marginTop: "5px",
              color: "red",
              fontFamily: "sans-serif",
              fontSize: "200",
              display: showError ? "flex" : "none",
            }}
          >
            {t("login.without_account")} {email}
          </span>

          <span
            style={{
              alignSelf: "flex-start",
              marginLeft: "5vw",
              marginTop: "5px",
              color: "red",
              fontFamily: "sans-serif",
              fontSize: "200",
              display: errorEmail ? "flex" : "none",
            }}
          >
            {t("login.email_verif")}
          </span>

          <span className="txt-mail-info-password">
            {t("form_password.saisir_mdp")}
          </span>
          <div
            className="container-email-register-password"
            style={{ backgroundColor: showError ? "#FCE6E7" : "white" }}
          >
            <input
              type="password"
              className="txt-email-password"
              style={{ backgroundColor: showError ? "#FCE6E7" : "white" }}
              placeholder="password"
              id="0.12504859747231034"
              name="password"
              onChange={handlePassword}
              value={password}
            />
          </div>

          <span
            style={{
              alignSelf: "flex-start",
              marginLeft: "5vw",
              marginTop: "5px",
              color: "red",
              fontFamily: "sans-serif",
              fontSize: "200",
              display: showError_password ? "flex" : "none",
            }}
          >
            {t("login.error_password_login")}
          </span>

          <span
            style={{
              alignSelf: "flex-start",
              marginLeft: "5vw",
              marginTop: "5px",
              color: "red",
              fontFamily: "sans-serif",
              fontSize: "200",
              display: showError_accout_active ? "flex" : "none",
            }}
          >
            {t("login.error_active")}
          </span>

          <div
            className="btn-commencer-login"
            onClick={navToFormPassword}
            style={{ pointerEvents: stateOfLoader ? "none" : "auto" }}
          >
            <div
              style={{
                width: "10vw",
                height: "6vh",
                display: stateOfLoader ? "flex" : "none",
              }}
            >
              <Loader />
            </div>
            <span
              className="txt-commencer-login"
              style={{ display: stateOfLoader ? "none" : "flex" }}
            >
              {t("login.continue")}
            </span>
          </div>

          <div className="txt-with-acount">
            <span className="txt-mm">
              {t("login.without_account")}
              <span
                className="navig-create-acount"
                onClick={navigateTocreateAcount}
              >
                {t("login.txt_inscrire")}
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
