/**** Coockie consent RGPD  */
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useTranslation } from "react-i18next";
/*************************************
 **************Import React core
 **************************************/
import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
/*************************************
 **************Import JS CODE
 **************************************/
import { AuthContext } from "./context/auth";
import PrivateRoute from "./hooks/privateRoute";
/*************************************
 **************Route Public
 **************************************/
import Header from "./view/_common/header";
import Login from "../src/view/login/login";
import Confidentialite from "../src/view/login/confidentialite";
import LoginFormGoogle from "../src/view/login/login-form-google";
import Register from "../src/view/login/register";
import RegisterFormMail from "../src/view/login/register-form-mail";
import LoginFormPassword from "./view/login/login-form-password";
import Valid_email from "./view/login/valid_email";
import Activation from "./view/login/activation";

import NotFound from "../src/view/not_found";
/*************************************
 **************Route Private
 **************************************/
import Accueil from "../src/view/accueil/accueil";
import CreateEvent from "../src/view/accueil/create-event";
import FormNewEventCreate from "../src/view/accueil/form-new-event-create";
import Modif_info_event from "../src/view/accueil/modif_info_event";
import Modif_info_dispo from "../src/view/accueil/modif_info_dispo";

import AllEvent from "./view/link/allEvent";
import SelectEvent from "./view/link/selectEvent";
import Form_select_event from "./view/link/form_select_event";
import Select_date_for_small from "./view/link/select_date_for_small";

import CalendarFull from "./view/accueil/calendar-file";
import Profil from "./view/profil/profil";

import { dataStore } from "./data/datastore";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import datastore, { persistor } from "../src/redux/stores";

export const ThemeContext = React.createContext({
  primaryColor: "deepskyblue",
});

function App(props) {
  const existObjectUser = JSON.parse(localStorage.getItem("user_object"));
  const [userObject, setUserObject] = useState(existObjectUser);
  const [store, setStore] = useState(dataStore);
  const { t, i18n } = useTranslation();

  const setUser = (data) => {
    localStorage.setItem("user_object", JSON.stringify(data));
    setUserObject(data);
  };

  return (
    <Provider store={datastore}>
      <PersistGate persistor={persistor}>
        <AuthContext.Provider
          value={{
            updateStore: setStore,
            store: store,
            userObject: userObject,
            setUserObject: setUser,
          }}
        >
          <Router>
            <Switch>
              <PrivateRoute
                exact
                path="/user/me/register"
                component={Register}
              />
              {/* <PrivateRoute
                exact
                path="/user/me/form_password"
                component={LoginFormPassword}
              /> */}
              <PrivateRoute
                exact
                path="/user/me/valid_email"
                component={Valid_email}
              />
              <PrivateRoute
                exact
                path="/user/me/login_with_google"
                component={LoginFormGoogle}
              />
              <PrivateRoute
                exact
                strict
                path="/user/me/accueil"
                component={Accueil}
              />
              <PrivateRoute
                exact
                path="/user/me/create_groupe"
                component={CreateEvent}
              />
              <PrivateRoute
                exact
                path="/user/me/form_event_create"
                component={FormNewEventCreate}
              />
              <PrivateRoute
                exact
                path="/user/me/modif_info_event"
                component={Modif_info_event}
              />
              <PrivateRoute
                exact
                path="/user/me/modif_info_dispo"
                component={Modif_info_dispo}
              />
              <PrivateRoute
                  exact
                  path="/user/me/profil"
                  component={Profil}
              />
              <PrivateRoute
                exact
                path="/user/me/calendar"
                component={CalendarFull}
              />
              <Route
                  exact
                  path="/user/me/login"
                  component={Login}
              />
              <Route
                  exact
                  path="/user/me/"
                  component={Login}
              />
              <Route
                  exact
                  path="/"
                  component={RegisterFormMail}
              />
              <Route
                exact
                path="/confidentialite"
                component={Confidentialite}
              />
              <Route
                exact
                path="/user/me/register-form-mail"
                component={RegisterFormMail}
              />
              <Route
                  exact
                  path="/:handle"
                  component={AllEvent}
              />
              <Route
                  exact
                  path="/:email/:link"
                  component={SelectEvent}
              />
              <Route
                exact
                path="/:email/:link/form"
                component={Form_select_event}
              />
              <Route
                exact
                path="/:email/:link/small/form"
                component={Form_select_event}
              />
              <Route
                exact
                path="/:email/:link/select_hour_for_mobile"
                component={Select_date_for_small}
              />
              <Route
                exact
                path="/user/me/activation/:key"
                component={Activation}
              />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </AuthContext.Provider>
      </PersistGate>
      <CookieConsent
        location="bottom"
        buttonText={t("rgpd.accept")}
        cookieName="myAwesomeCookieName2"
        style={{
          background: "#2B373B",
          display: window.location.href.includes("ifram") ? "none" : "flex",
        }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {t("rgpd.declaration")}
        <a href="/confidentialite"> {t("rgpd.read_more")}</a>
      </CookieConsent>
    </Provider>
    // <CalendarFull></CalendarFull>
  );
}

export default App;
