const mapStateToProps = (state) => {
  console.log("mapStateToProps => ", state);
  return {
    //////////////////////////////////////////////////////////////////////////
    // OBJET USER
    //////////////////////////////////////////////////////////////////////////
    eventReducers: state.eventReducers,
    //...state,
  };
};
export default mapStateToProps;
