import React, { useRef, useState } from "react";
import ProfilService from "../../service/profilService";
import TimezonePicker from "react-timezone";
import { CountryDropdown } from "react-country-region-selector";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/auth";
import Header from "../_common/header";
import { useTranslation } from "react-i18next";
//import Avatar                   from "./Avatar";
import Select from "react-select";
import "../../style/profil/profil.css";
import { Tabs, Tab } from "react-bootstrap";

import { Avatar } from "@material-ui/core";
import { UploadPicture, Button } from "./_common/index";
import ChangePassword from "./ChagePassword";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faSave, faCamera } from "@fortawesome/free-solid-svg-icons";
import { suppr_acc_car_spec, validateUrl } from "../../utils/index";

const PROFIL_PIC_URL = process.env.REACT_APP_PROFIL_PIC_URL;
export default function Profil() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { userObject, setUserObject } = useAuth();

  const [selectedCountry, setSelectedCountry] = useState(
    userObject.profil.country
  );
  const [selectedTimezone, setSelectedTimezone] = useState(
    userObject.profil.timezone
  );
  const [currentDescription, setCurrentDescription] = useState(
    userObject.profil.description
  );
  const [currentLink, setCurrentLink] = useState(userObject.profil.link);
  const [currentName, setCurrentName] = useState(userObject.username);

  const [alert, displayAlert] = useState(false);
  const [alertMessage, setMessage] = useState(false);

  function logout() {
    setUserObject({});
    localStorage.setItem("user_log", "");
    history.push("/user/me/login");
  }

  function makeLink() {
    let valueAfterMake = suppr_acc_car_spec(currentLink);
    console.log("valueAfterMakevalueAfterMake", valueAfterMake);
    setCurrentLink(valueAfterMake);
  }

  function updateProfilInfos(e) {
    const data = {
      user_id: userObject.id,
      user_name: currentName,
      profil_link: currentLink,
      country: selectedCountry ? selectedCountry : "France",
      timezone: selectedTimezone,
      description: currentDescription,
    };

    ProfilService.updateProfilInfos(data).then(function (response) {
      if (response.data.success) {
        setUserObject(response.data.data);
        setMessage(t("update_succes"));
        displayAlert(true);
        setTimeout(() => {
          displayAlert(false);
        }, 1000);
      }
    });
  }

  const [profilePic, SetProfilePic] = useState(userObject.profil.picture_name);
  const [pictureSource, setPictureSource] = useState(
    PROFIL_PIC_URL + userObject.profil.picture_name
  );
  const handleUploadImage = (uploadedFile) => {
    const data = {
      user_id: userObject.id,
      profil_pic: {
        file: uploadedFile.base64,
        //type: uploadedFile.type,//Default png
        name: uploadedFile.name,
      },
    };
    ProfilService.updateProfilPic(data).then(function (response) {
      if (response.data.success) {
        setUserObject(response.data.data);
        setPictureSource(
          PROFIL_PIC_URL + response.data.data.profil.picture_name
        );
      }
    });
  };
  return (
    <>
      <Header />
      <div className="container-profil">
        <div className="profil-body">
          <Button className="logout-btn" onClick={logout}>
            {" "}
            {t("word.logout")}{" "}
          </Button>
          <Tabs defaultActiveKey="profile-infos" id="uncontrolled-tab-example">
            <Tab eventKey="profile-infos" title="Mon compte">
              <div className="row">
                <div className="col-sm-4">
                  <div className="profil-picture">
                    <Avatar
                      alt="Profile"
                      src={pictureSource}
                      className="section-header-avatar"
                    />
                    <UploadPicture
                      onUploadImage={handleUploadImage}
                      buttonText="Upload New Picture"
                    />
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="">
                    <div className="compte-infos">
                      <div className="form-group">
                        <label>Email </label>
                        <input
                          className="input-control"
                          type="text"
                          value={userObject.email}
                          readOnly
                        />
                      </div>

                      <div className="form-group">
                        <label>Name</label>
                        <input
                          className="input-control"
                          type="text"
                          value={currentName}
                          onChange={(data) => setCurrentName(data.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Link</label>
                        <input
                          className="input-control"
                          type="text"
                          value={currentLink}
                          onChange={(data) => setCurrentLink(data.target.value)}
                          onBlur={makeLink}
                        />
                      </div>
                      <div className="form-group">
                        <label>Country</label>
                        <CountryDropdown
                          value={selectedCountry}
                          className="input-control"
                          onChange={(val) => setSelectedCountry(val)}
                        />
                      </div>

                      <div className="form-group timezone-dropdown">
                        <label>Time Zone</label>
                        <TimezonePicker
                          className="timezone-selector"
                          value="Asia/Yerevan"
                          onChange={(timezone) => setSelectedTimezone(timezone)}
                          inputProps={{
                            placeholder: "Select Timezone...",
                            name: "timezone",
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>Description</label>
                        <textarea
                          className="input-control"
                          value={currentDescription}
                          onChange={(data) =>
                            setCurrentDescription(data.currentTarget.value)
                          }
                        />
                      </div>
                      <div
                        className={
                          "alert alert-success " + (!alert ? "d-none" : "")
                        }
                      >
                        {alertMessage}
                      </div>
                      <div class="form-group text-right btn-wrapper">
                        <Button
                          className="button button-inverted"
                          variant="outlined"
                          onClick={updateProfilInfos}
                        >
                          {t("word.cancel")}
                        </Button>
                        <Button
                          className="button ml-3"
                          onClick={updateProfilInfos}
                        >
                          <FontAwesomeIcon icon={faSave} className="mr-2" />
                          {t("word.edit")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="" title="Change password">
              <ChangePassword />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}
