import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function ListEvent(props) {
  const classes = useStyles();
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return width;
    // return {
    //   width,
    //   height,
    // };
  }

  return (
    <Card
      className={classes.root}
      variant="outlined"
      style={{
        marginTop: "15px",
        width: getWindowDimensions() < 600 ? "100vw" : "80vw",
      }}
    >
      <CardContent>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "space-between",
                width: "20vw",
              }}
            >
              <span style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}>
                {props.subscriber &&
                  moment(props.subscriber.date, "MM-DD-YYYY").format("dddd") +
                    "," +
                    moment(
                      props.subscriber.date && props.subscriber.date,
                      "MM-DD-YYYY"
                    ).format("DD MMMM YYYY")}
              </span>
              <span style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}>
                {props.title && props.title}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "15vw",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  marginRight: "12px",
                  backgroundColor: props.color,
                }}
              ></div>
              <span style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}>
                {props.subscriber && props.subscriber.hour}-{" "}
                {props.subscriber && props.subscriber.hour_end}
              </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "space-between",
                width: "15vw",
              }}
            >
              <span style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}>
                {props.subscriber && props.subscriber.email}
              </span>

              <span style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}>
                {" "}
                {props.subscriber && props.subscriber.name}
              </span>
              <span style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}>
                {props.subscriber && props.subscriber.phone}
              </span>
            </div>

            <div
              style={{
                width: "25vw",
                fontFamily: "Proxima Nova",
                fontWeight: "bold",
              }}
            >
              {props.subscriber && props.subscriber.note}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
