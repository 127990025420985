import React, { useState, useEffect, useCallback } from "react";
import { Dialog } from "@material-ui/core";
import { Button } from "../profil/_common/index";
import { useTranslation } from "react-i18next";

export default function ConfirmDialog(props) {
  const { t, i18n } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(props.open);

  const handleDialogClose = () => {
    setDialogOpen(false);
    props.onNo();
  };

  useEffect(() => {
    setDialogOpen(props.open);
  }, [props.open]);

  return (
    <Dialog onClose={handleDialogClose} open={dialogOpen}>
      <div className="acount-button-wrapper">
        <div className="confirm-message-wrapper">{props.message}</div>
        <Button
          className="button-md button-inverted"
          variant="outlined"
          onClick={props.onNo}
        >
          {t("word.cancel")}
        </Button>
        <Button className="button-md" onClick={props.onYes}>
          {t("word.yes")}
        </Button>
      </div>
    </Dialog>
  );
}
