import React, { useEffect } from "react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { useHistory, Redirect } from "react-router-dom";
import { valid_url_token } from "../../service/backEndService";
import { ReactComponent as Chargement } from "../../style/resource/ll.svg";

export default function Activation(props) {
  const foo = props.match && props.match.params.key; // bar
  const history = useHistory();

  useEffect(() => {
    async function fetchMyAPI() {
      // setLoader(true);
      let param = {
        token: foo,
      };
      let res = await valid_url_token(param);
      console.log("a", res);
      if (res.data && res.data.success) {
        history.push("/user/me/login");
      }
      // setLoader(false);
    }
    fetchMyAPI();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          height: "100vh",
        }}
      >
        <Chargement />
      </div>
    </div>
  );
}
