import React from 'react';
import Button from '@material-ui/core/Button';

function ButtonOutlined(props) {
    return (
        <Button 
            variant="outlined"
            disableRipple={true}
            {...props}
        >
            {props.children}
        </Button>
    );
}

export default ButtonOutlined;