import React,{useRef, useState} from "react";
import { useAuth }              from "../../context/auth";
import ProfilService            from '../../service/profilService';
import { Button }               from './_common/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faSave } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

export default  function ChangePassword(props)
{
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { userObject, setUserObject }  = useAuth();

    const [currentPassword, SetCurrentPassword]  = useState();
    const [newPassword, SetNewPassword]          = useState();
    const [confirmPassword, SetConfirmPassword]  = useState();

    const [alert,displayAlert] = useState(false);
    const [alertMessage,setMessage] = useState(false);

    function handleChangePassword(){
        displayAlert(false);
        //Check 
        if(!currentPassword){
            error("Current password is required");
            return false; 
        }
        
        if(!newPassword){
            error("New password is required");
            return false; 
        }
        
        
        if(newPassword != confirmPassword ){
            error("The two new password must be the same");
            return false; 
        }
        const data = 
        {
            "email"            : userObject.email ,
            "current_password" : currentPassword,
            "new_password"     : newPassword,
        };  
    
        ProfilService.updateProfilPassword(data).then(function (response) {
        console.log(response.data);
        if(response.data.success){
            setUserObject({});
            history.push("/login");
            
        }else{
            error(response.data.message);   
            
        }
        
        });
    }
    function error(e){
        setMessage(e)
        displayAlert(true);
    }

    return (
        <div className="row">
            <div className="col-sm-4">
                <div className="profil-picture"> 
                    <FontAwesomeIcon
                        icon={faLock}
                        size="8x"
                    />
                </div>
            </div>
            <div className="col-sm-8">
            <div className="">
                <div className="compte-infos">
                    <div className="form-group">
                        <label>Current password</label>
                        <input className="form-control" type="password" value={ currentPassword } onChange={data => SetCurrentPassword(data.target.value)} />
                    </div>

                    <div className="form-group">
                        <label>New password</label>
                        <input className="form-control" type="password" value={ newPassword } onChange={data => SetNewPassword(data.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Confim password</label>
                        <input className="form-control" type="password" value={ confirmPassword } onChange={data => SetConfirmPassword(data.target.value) } />
                    </div>

                    <div className={"alert alert-danger " + (!alert?'d-none':'')}>
                          {alertMessage}
                    </div>
                    
                    <div class="form-group text-right btn-wrapper">
                        <Button className="button button-inverted" variant="outlined" >
                                Cancel
                        </Button>
                        <Button className="button ml-3" onClick={ handleChangePassword } >
                            <FontAwesomeIcon icon={faSave} className="mr-2"/>
                            Save change
                        </Button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )


}
