import React, { useState, useEffect } from "react";
import "../../style/login/registerFormMail.css";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import logo from "../../../src/style/resource/logo.jpeg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { checkEmail } from "../../service/backEndService";
import { ReactComponent as Loader } from "../../style/resource/loaderTreePoint.svg";
import { useAuth } from "../../context/auth";
import eventHandler from "../../hooks/event_handler";
import { isValidEmail } from "../../utils/index";
import Header from "./../_common/header";
import { useTranslation } from "react-i18next";
import PageService from "../../service/pageService";
import parse from "html-react-parser";

//Formulaire avec un Input pour register
const background_url = process.env.REACT_APP_API_URL + "media/image/";
export default function RegisterFormMail(props) {
  const history = useHistory();
  const { userObject, setUserObject, changeLanguage, langue, data } = useAuth();
  const { t, i18n } = useTranslation();

  const [stateOfLoader, setStateOfLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const DefaultBg = "";
  const [description, setDescription] = useState();
  const [homeData, SetHomeData] = useState();

  useEffect(() => {
    PageService.getHomeContent().then(function (response) {
      console.log("response.data.data", response.data.data);
      SetHomeData(response.data.data);
    });
  }, [null]);

  function navigateLogin() {
    history.push("/user/me/login");
  }
  function navToAccueil() {
    history.push("/user/me/login_with_google");
  }

  function handleEmail(evt) {
    setShowError(false);
    setErrorEmail(false);
    if (evt.target) {
      setEmail(evt.target.value.trim());
    }
  }
  async function navToAccueilFormRegister() {
    if (isValidEmail(email)) {
      setStateOfLoader(true);
      let res = await checkEmail(email);
      console.log("navToAccueil", res);
      if (res && res.data.success && res.data.Message == "Email exist") {
        console.log("ATO");
        setShowError(true);
        setStateOfLoader(false);
        // history.push("/register");
      } else {
        setUserObject({ email: email, name: "jose", firstName: "tt" });
        history.push({
          pathname: "/user/me/register",
          state: {
            email,
          },
        });
      }
    } else {
      setStateOfLoader(false);
      setErrorEmail(true);
    }

    // history.push("/accueil");
  }

  const keyEnter = ({ key }) => {
    console.log("Evt", key);
    if (key == "Enter") {
      navToAccueilFormRegister();
    } else return;
  };

  eventHandler("keydown", keyEnter);

  return (
    <>
      <Header></Header>
      <div
        className="body-wrapper h-100"
        style={{
          background:
            "url('" +
            (homeData
              ? i18n.language == "fr"
                ? background_url + homeData.fr.background
                : background_url + homeData.en.background
              : DefaultBg) +
            "')",
          height: "100vh !important",
          width: "100vw !important",
        }}
      >
        <div
          className="container-fluid form-wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="container-header-register-mail">
            {/* <img src={logo} className="icon-rounded-logo"></img> 
            <p className="title-login-register">
              {t("register_form_email.txt_inscription")}
            </p> */}
          </div>
          <div className="container-form register-form-control">
            <div className="homeDescription mt-2">
              {homeData
                ? parse(
                    i18n.language == "fr"
                      ? homeData.fr.content
                      : homeData.en.content
                  )
                : ""}
            </div>

            <span className="txt-mail-info-register-for-mail">
              {t("register_form_email.enter_email")}
            </span>
            <div className="container-email-register-form_email">
              <input
                type="text"
                className="input-control_register_for_mail "
                placeholder={t("register_form_email.email")}
                id="0.12504859747231034"
                onChange={handleEmail}
                value={email}
              />
              <span
                className="compte_existe"
                style={{
                  display: showError ? "inline" : "none",
                }}
              >
                {t("register_form_email.error_compe_existe")}
              </span>

              <span
                className="compte_existe"
                style={{
                  display: errorEmail ? "inline" : "none",
                }}
              >
                {t("register_form_email.email_format")}
              </span>
            </div>
            <div
              className="btn-commencer-register-email-form-email"
              onClick={navToAccueilFormRegister}
              style={{ pointerEvents: stateOfLoader ? "none" : "auto" }}
            >
              <div
                style={{
                  width: "10vw",
                  height: "6vh",
                  display: stateOfLoader ? "flex" : "none",
                }}
              >
                <Loader />
              </div>
              <span
                className="txt-commencer-register-form"
                style={{ display: stateOfLoader ? "none" : "flex" }}
              >
                {t("register_form_email.continue")}
              </span>
            </div>

            <div className="txt-with-acount">
              <span className="txt-mm">
                {t("register_form_email.pas_de_compte")}
                <span className="navig-create-acount" onClick={navigateLogin}>
                  {t("register_form_email.login")}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
