import React, { useState } from "react";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";
import { connect } from "react-redux";
import "../../style/accueil/modif_info_event_style.css";
import Header from "../_common/header";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import { useHistory } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import { useAuth } from "../../context/auth";
import momentTimeZone from "moment-timezone";
import "moment/locale/zh-cn";
import "moment/locale/en-gb";
import { useTranslation } from "react-i18next";
import { ReactComponent as Loader } from "../../style/resource/loaderTreePoint.svg";
import { suppr_acc_car_spec } from "../../utils/index";
import Radio from "@material-ui/core/Radio";

function Modif_info_event(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { userObject, setUserObject, updateStore, data } = useAuth();
  const [name_event, setName_event] = useState(props && props.location.state && props.location.state.title);
  const [color, setColor] = useState(props && props.location.state && props.location.state.color);
  const [lieu, setLieu] = useState(props && props.location.state && props.location.state.location);
  const [description, setDescription] = useState(props && props.location.state && props.location.state.description);
  const [lien, setLien] = useState(props && props.location.state && props.location.state.link);
  const [nombre_inviter, setNombre_inviter] = useState(
    props && props.location.state && props.location.state.type === "event_group"
      ? props && props.location.state && props.location.state.max_invite
      : ""
  );
  const [type_event, setEventType] = useState(props && props.location.state && props.location.state.type);
  const [selectedValue, setSelectedValue] = React.useState(props && props.location.state && props.location.state.location_type);
  const [id_event_modif, setId_event_modif] = React.useState(props && props.location.state && props.location.state.id);
  const [showLoader, setShowLoader] = useState(false);
  const [is_valid_title, checkIfValidTitle] = useState(true);
  const [isvalid_link, checkIfvalid_link] = useState(true);
  const [pseudoLink, setPseudoLink] = useState(userObject && userObject.email.split("@")[0]);
  const [valueLink, setValueLink] = useState(pseudoLink + lien);

  function backToGroupeType() {
    history.goBack();
  }

  function logout() {
    setUserObject({});
    history.push("/user/me/login");
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  function handle_event_name(evt) {
    checkIfValidTitle(true);
    setName_event(evt.target.value);
  }

  function handleColor(evt) {
    setColor(evt.target.value);
  }

  function handleLieux(evt) {
    setLieu(evt.target.value);
  }

  function handleDescription(evt) {
    setDescription(evt.target.value);
  }

  function handleLien(evt) {
    checkIfvalid_link(true);
    setLien(evt.target.value);
  }

  function makeLink() {
    let valueAfterMake = suppr_acc_car_spec(lien);
    setLien(valueAfterMake);
  }

  function handle_Nbr_event(evt) {
    setNombre_inviter(evt.target.value);
  }

  const mofifier_event = async (user_id, title, link, description, location, color, max_invite) => {
    setShowLoader(true);
    let res;
    let data;
    if (max_invite) {
      data = {
        user_id: user_id,
        event_id: id_event_modif,
        title: title,
        link: suppr_acc_car_spec(lien),
        description: description,
        color: color,
        timezone: momentTimeZone.tz.guess(),
        max_invite: max_invite,
        location: location,
        location_type: selectedValue,
        duration: 60,
      };
    } else {
      data = {
        user_id: user_id,
        event_id: id_event_modif,
        title: title,
        link: suppr_acc_car_spec(lien),
        description: description,
        color: color,
        timezone: momentTimeZone.tz.guess(),
        location: location,
        location_type: selectedValue,
        duration: 60,
      };
    }

    if (title === "" || suppr_acc_car_spec(lien) === "" || description === "" || location === "" || selectedValue === "") {
      alert(t("form_event_create.vide"));
      setShowLoader(false);
    } else {
      if (title.length > 25) {
        setShowLoader(false);
        checkIfValidTitle(false);
      } else if (lien.length > 15) {
        setShowLoader(false);
        checkIfvalid_link(false);
      } else {
        await props.put_event_info(data);
        history.push("/user/me/accueil");
        setShowLoader(false);
      }
    }
    setShowLoader(false);
  };

  return (
    <>
      <Header />
      <div className="container-root-form_modif">
        <div className="container-form-create_modif">
          <div className="container-title-liste_modif">
            <div className="btn-back-form" onClick={backToGroupeType}>
              <NavigateBefore color="primary" style={{ color: "#00a2ff" }} />
              <span
                className="txt-new-event_modif"
                style={{ color: "#00a2ff" }}
              >
                {t("form_event_create.back")}
              </span>
            </div>
            <div className="txt-add-type_modif">
              {t("form_event_create.modifier_title")}
              {""}
              {/* {type_event == "event"
                ? t("accueil.private")
                : t("accueil.groupe")} */}
            </div>
            <div
              className="type-of-event_modif"
              style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}
            >
              {t("form_event_create.type")}
            </div>
          </div>
          <div className="line-create_modif"/>
          <div className="container-formulaire_modif">
            <div className="content-border_modif">
              <div className="colom-row-style_modif">
                <div
                    className="rounder-bouton_modif"
                    style={{backgroundColor: color}}
                />
                <span
                  style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}
                >
                  {t("form_event_create.what_event")}
                </span>
              </div>
              <div className="colom-row-style-rigth_modif">
                <div
                  className="btn-next"
                  onClick={() => {
                    mofifier_event(
                      userObject && userObject.id,
                      name_event,
                      valueLink,
                      description,
                      lieu,
                      color,
                      nombre_inviter
                    );
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Proxima Nova",
                      fontWeight: "bold",
                      display: showLoader ? "none" : "flex",
                    }}
                  >
                    {t("form_event_create.modier")}
                  </span>
                  <Loader style={{ display: showLoader ? "flex" : "none" }} />
                </div>
                <span
                  className="reset-btn_modif"
                  style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}
                  onClick={() => backToGroupeType()}
                >
                  {t("form_event_create.annuler")}
                </span>
              </div>
            </div>
            <div className="line-unde-menu_modif"/>
            <div className="container-form-text_modif">
              <div className="txt-title-name_modif">
                {t("form_event_create.event_name")}
                <span
                  style={{
                    fontSize: "small",
                    marginTop: "5vh",
                    color: "red",
                    display: is_valid_title ? "none" : "flex",
                  }}
                >
                  {t("form_event_create.mes_error_title")}
                </span>
              </div>
              <div className="container-name-event_modif">
                <input
                  type="text"
                  className="txt-event_modif"
                  style={{ fontWeight: "bold" }}
                  placeholder="Event Name"
                  id="0.1250485974712231034"
                  value={name_event}
                  onChange={handle_event_name}
                />
              </div>
              <div className="txt-title-lieu_modif">
                {t("form_event_create.type_rdv")}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Radio
                  checked={selectedValue === "Physique"}
                  onChange={handleChange}
                  value="Physique"
                  style={{ fontWeight: "bold" }}
                  color="default"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "D" }}
                />
                <span
                  style={{
                    fontFamily: "Proxima Nova",
                    color: "#4d5055",
                    fontSize: "medium",
                  }}
                >
                  {t("form_event_create.physique")}
                </span>
                <Radio
                  checked={selectedValue === "a distance"}
                  onChange={handleChange}
                  value="a distance"
                  color="default"
                  style={{ fontWeight: "bold" }}
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "D" }}
                />
                <span
                  style={{
                    fontFamily: "Proxima Nova",
                    color: "#4d5055",
                    fontSize: "medium",
                  }}
                >
                  {t("form_event_create.distance")}
                </span>
              </div>
              <div
                className="txt-title-lieu-type_modif"
                style={{
                  display: selectedValue === "Physique" ? "flex" : "none",
                }}
              >
                {t("form_event_create.lieu")}
              </div>
              <div
                className="container-name-event_modif"
                style={{
                  display: selectedValue === "Physique" ? "flex" : "none",
                }}
              >
                <input
                  type="text"
                  className="txt-event_modif"
                  placeholder="Adresse"
                  id="0.12559747231034"
                  style={{ fontWeight: "bold" }}
                  value={lieu}
                  onChange={handleLieux}
                />
              </div>
              <div
                className="txt-title-lieu-type_modif"
                style={{
                  display: selectedValue === "a distance" ? "flex" : "none",
                }}
              >
                A distance
              </div>
              <div
                className="container-name-event_modif"
                style={{
                  display: selectedValue === "a distance" ? "flex" : "none",
                }}
              >
                <input
                  type="text"
                  className="txt-event_modif"
                  placeholder={t("form_event_create.placeholder_distance")}
                  id="0.12559747231034"
                  style={{ fontWeight: "bold" }}
                  value={lieu}
                  onChange={handleLieux}
                />
              </div>

              <div className="txt-title-lieu_modif">
                {" "}
                {t("form_event_create.des")}
              </div>
              <div className="container-name-event-area_modif">
                <textarea
                  className="txt-event-area_modif"
                  value={description}
                  style={{ fontWeight: "bold" }}
                  onChange={handleDescription}
                ></textarea>
              </div>
              <div className="txt-title-lieu_modif">
                {t("form_event_create.link")}
                <span
                  style={{
                    fontSize: "small",
                    marginTop: "5vh",
                    color: "red",
                    display: isvalid_link ? "none" : "flex",
                  }}
                >
                  {t("form_event_create.mes_error_lien")}
                </span>
              </div>
              <div
                style={{
                  marginTop: "3vh",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginRight: "20px",
                    fontFamily: "Proxima Nova",
                    fontWeight: "bold",
                    color: "#4d5055",
                  }}
                >
                  ListooRDV.com/{pseudoLink}/
                </span>
                <div className="container-name-event-form-create_modif">
                  <input
                    type="text"
                    className="txt-event-lien_modif"
                    placeholder="lien evenement"
                    id="0.12504859747034"
                    value={lien}
                    onChange={handleLien}
                    onBlur={makeLink}
                    style={{ fontWeight: "bold" }}
                  />
                </div>
              </div>
              <div
                className="txt-title-number_modif"
                style={{
                  display: type_event === "event" ? "none" : "flex",
                  marginTop: "5vh",
                }}
              >
                {t("form_event_create.nbr")}
              </div>
              <div
                className="container-name-event-number_modif"
                style={{ display: type_event === "event" ? "none" : "flex" }}
              >
                <input
                  type="number"
                  min="1"
                  className="txt-event-number_modif"
                  placeholder=""
                  style={{ fontWeight: "bold" }}
                  id="0.125048597471332231034"
                  value={nombre_inviter}
                  onChange={handle_Nbr_event}
                />
              </div>
              <div className="txt-title-lieu_modif">
                {t("form_event_create.event_color")}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "4vh",
                }}
              >
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#8989fc",
                    borderRadius: "50%",
                    fontFamily: "Proxima Nova",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => setColor("#8989fc")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#8989fc" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#FF0000",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#FF0000")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#FF0000" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#F778B4",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#F778B4")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#F778B4" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#4A91E9",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#4A91E9")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#4A91E9" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#2CC0D7",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#2CC0D7")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#2CC0D7" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#34C76E",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#34C76E")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#34C76E" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#67C820",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#67C820")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#67C820" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#DFC12E",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#DFC12E")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#DFC12E" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#F49A30",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#F49A30")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#F49A30" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#A4A230",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#A4A230")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#A4A230" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
              </div>
              <div className="colom-row-style-rigth_modif">
                <div
                  className="btn-next_modif"
                  onClick={() => {
                    mofifier_event(
                      userObject && userObject.id,
                      name_event,
                      valueLink,
                      description,
                      lieu,
                      color,
                      nombre_inviter
                    );
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Proxima Nova",
                      fontWeight: "bold",
                      display: showLoader ? "none" : "flex",
                    }}
                  >
                    {t("form_event_create.modier")}
                  </span>
                  <Loader style={{ display: showLoader ? "flex" : "none" }} />
                </div>
                <span
                  className="reset-btn_modif"
                  style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}
                  onClick={() => backToGroupeType()}
                >
                  {t("form_event_create.annuler")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Modif_info_event);
