import React, {useState} from "react";
import "../../style/accueil/form-new-event.css";
import Header from "../_common/header";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import {useHistory} from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import {useAuth} from "../../context/auth";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import "moment/locale/zh-cn";
import "moment/locale/en-gb";
import {useTranslation} from "react-i18next";
import {
  create_event,
  create_event_groupe,
  post_disponibilityes
} from "../../service/backEndService";
import {ReactComponent as Loader} from "../../style/resource/loaderTreePoint.svg";
import {
  suppr_acc_car_spec,
  validateUrl
} from "../../utils/index";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

export default function FormNewEventCreate(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { userObject, setUserObject, updateStore, data } = useAuth();
  const [name_event, setName_event] = useState("");
  const [color, setColor] = useState("#8989fc");
  const [lieu, setLieu] = useState("");
  const [description, setDescription] = useState("");
  const [lien, setLien] = useState("");
  const [nombre_inviter, setNombre_inviter] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [type_event, setEventType] = useState(props.location && props.location.state);
  const [is_valid_title, checkIfValidTitle] = useState(true);
  const [isvalid_link, checkIfvalid_link] = useState(true);
  const [pseudoLink, setPseudoLink] = useState(userObject && userObject.email.split("@")[0]);
  const [selectedValue, setSelectedValue] = React.useState("Physique");
  const [valueLink, setValueLink] = useState(pseudoLink + lien);
  const [intervalClick, setIntervalClick] = useState({
    monday: [
      {
        from: "09:00",
        to: "17:00",
      }
    ],
    tuesday: [
      {
        from: "09:00",
        to: "17:00",
      }
    ],
    wednesday: [
      {
        from: "09:00",
        to: "17:00",
      }
    ],
    thursday: [
      {
        from: "09:00",
        to: "17:00",
      }
    ],
    friday: [
      {
        from: "09:00",
        to: "17:00",
      }
    ],
    saturday: [
      {
        from: "09:00",
        to: "17:00",
      }
    ],
    sunday: [
      {
        from: "09:00",
        to: "17:00",
      }
    ]
  });
  const [eventMonth, setEventMonth] = useState(2);

  let availabilities = [];

  function backToGroupeType() {
    history.goBack();
  }

  function logout() {
    setUserObject({});
    history.push("/user/me/login");
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  function handle_event_name(evt) {
    checkIfValidTitle(true);
    setName_event(evt.target.value);
  }

  function handleColor(evt) {
    setColor(evt.target.value);
  }

  function handleLieux(evt) {
    setLieu(evt.target.value);
  }

  function handleDescription(evt) {
    setDescription(evt.target.value);
  }

  function handleLien(evt) {
    checkIfvalid_link(true);
    setLien(evt.target.value);
  }

  function makeLink() {
    let valueAfterMake = suppr_acc_car_spec(lien);
    setLien(valueAfterMake);
  }

  function handle_Nbr_event(evt) {
    setNombre_inviter(evt.target.value);
  }

  const remove_intervale = (index, day) => {
    switch (day) {
      case 'monday':
        let mondayData = intervalClick && intervalClick.monday;
        mondayData.splice(index, 1);
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          monday: [...mondayData],
        }));
        break;
      case 'tuesday':
        let tuesdayData = intervalClick && intervalClick.tuesday;
        tuesdayData.splice(index, 1);
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          tuesday: [...tuesdayData],
        }));
        break;
      case 'wednesday':
        let wednesdayData = intervalClick && intervalClick.wednesday;
        wednesdayData.splice(index, 1);
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          wednesday: [...wednesdayData],
        }));
        break;
      case 'thursday':
        let thursdayData = intervalClick && intervalClick.thursday;
        thursdayData.splice(index, 1);
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          thursday: [...thursdayData],
        }));
        break;
      case 'friday':
        let fridayData = intervalClick && intervalClick.friday;
        fridayData.splice(index, 1);
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          friday: [...fridayData],
        }));
        break;
      case 'saturday':
        let saturdayData = intervalClick && intervalClick.saturday;
        saturdayData.splice(index, 1);
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          saturday: [...saturdayData],
        }));
        break;
      case 'sunday':
        let sundayData = intervalClick && intervalClick.sunday;
        sundayData.splice(index, 1);
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          sunday: [...sundayData],
        }));
        break;
    }
  };

  const changeFrom = (index_from, evt, day) => {
    switch (day) {
      case 'monday':
        let mondayData = intervalClick && intervalClick.monday;
        mondayData[index_from].from = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          monday: [...mondayData],
        }));
        break;
      case 'tuesday':
        let tuesdayData = intervalClick && intervalClick.tuesday;
        tuesdayData[index_from].from = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          tuesday: [...tuesdayData],
        }));
        break;
      case 'wednesday':
        let wednesdayData = intervalClick && intervalClick.wednesday
        wednesdayData[index_from].from = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          wednesday: [...wednesdayData],
        }));
        break;
      case 'thursday':
        let thursdayData = intervalClick && intervalClick.thursday;
        thursdayData[index_from].from = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          thursday: [...thursdayData],
        }));
        break;
      case 'friday':
        let fridayData = intervalClick && intervalClick.friday;
        fridayData[index_from].from = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          friday: [...fridayData],
        }));
        break;
      case 'saturday':
        let saturdayData = intervalClick && intervalClick.saturday;
        saturdayData[index_from].from = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          saturday: [...saturdayData],
        }));
        break;
      case 'sunday':
        let sundayData = intervalClick && intervalClick.sunday;
        sundayData[index_from].from = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          sunday: [...sundayData],
        }));
        break;
    }
  };

  const changeTo = (index_to, evt, day) => {
    switch (day) {
      case 'monday':
        let mondayData = intervalClick.monday;
        mondayData[index_to].to = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          monday: [...mondayData],
        }));
        break;
      case 'tuesday':
        let tuesdayData = intervalClick.tuesday;
        tuesdayData[index_to].to = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          tuesday: [...tuesdayData],
        }));
        break;
      case 'wednesday':
        let wednesdayData = intervalClick.wednesday;
        wednesdayData[index_to].to = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          wednesday: [...wednesdayData],
        }));
        break;
      case 'thursday':
        let thursdayData = intervalClick.thursday;
        thursdayData[index_to].to = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          thursday: [...thursdayData],
        }));
        break;
      case 'friday':
        let fridayData = intervalClick.friday;
        fridayData[index_to].to = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          friday: [...fridayData],
        }));
        break;
      case 'saturday':
        let saturdayData = intervalClick.saturday;
        saturdayData[index_to].to = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          saturday: [...saturdayData],
        }));
        break;
      case 'sunday':
        let sundayData = intervalClick.sunday;
        sundayData[index_to].to = evt.target.value;
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          sunday: [...sundayData],
        }));
        break;
    }
  };

  const makeForm = (day) => {
    switch (day) {
      case 'monday':
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          monday: [
            ...intervalClick.monday,
            {
              from: "",
              to: "",
            },
          ],
        }));
        break;
      case 'tuesday':
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          tuesday: [
            ...intervalClick.tuesday,
            {
              from: "",
              to: "",
            },
          ],
        }));
        break;
      case 'wednesday':
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          wednesday: [
            ...intervalClick.wednesday,
            {
              from: "",
              to: "",
            },
          ],
        }));
        break;
      case 'thursday':
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          thursday: [
            ...intervalClick.thursday,
            {
              from: "",
              to: "",
            },
          ],
        }));
        break;
      case 'friday':
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          friday: [
            ...intervalClick.friday,
            {
              from: "",
              to: "",
            },
          ],
        }));
        break;
      case 'saturday':
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          saturday: [
            ...intervalClick.saturday,
            {
              from: "",
              to: "",
            },
          ],
        }));
        break;
      case 'sunday':
        setIntervalClick((intervalClick) => ({
          ...intervalClick,
          sunday: [
            ...intervalClick.sunday,
            {
              from: "",
              to: "",
            },
          ],
        }));
        break;
    }
  };

  const makeAvailabilities = () => {
    let day = ['sunday', 'monday','tuesday','wednesday','thursday','friday','saturday'];
    let dayIndex = new Date().getDay();
    for (let i = 0; i < eventMonth * 4; i++){
      for (let j = dayIndex; j < dayIndex + 7; j++) {
        let intervals;
        switch (day[j % 7]) {
          case 'monday':
            intervals = intervalClick.monday
            break;
          case 'tuesday':
            intervals = intervalClick.tuesday
            break;
          case 'wednesday':
            intervals = intervalClick.wednesday
            break;
          case 'thursday':
            intervals = intervalClick.thursday
            break;
          case 'friday':
            intervals = intervalClick.friday
            break;
          case 'saturday':
            intervals = intervalClick.saturday
            break;
          case 'sunday':
            intervals = intervalClick.sunday
            break;
        }
        availabilities.push(
          {
            id: i * 7 + j - dayIndex,
            date: moment().add("day", i * 7 + j - dayIndex).format(),
            type: "date",
            intervales: intervals,
          }
        )
      }
    }
  }

  const create_groupe = async (user_id, title, link, description, location, color, max_invite) => {
    setShowLoader(true);
    let res;
    let data = {
      user_id: user_id,
      title: title,
      link: suppr_acc_car_spec(lien),
      description: description,
      color: color,
      timezone: momentTimeZone.tz.guess(),
      max_invite: max_invite,
      location: location,
      location_type: selectedValue,
      duration: 60,
      date_begining: moment().format("DD-MM-YYYY"),
      date_end: moment().add("day", eventMonth * 4 * 7).format("DD-MM-YYYY"),
    };

    let avalidateUr = validateUrl(location);

    if (title === "" || suppr_acc_car_spec(lien) === "" || description === "" || location === "" || selectedValue === "") {
      alert(t("form_event_create.vide"));
      setShowLoader(false);
    } else {
      if (title.length > 25) {
        setShowLoader(false);
        checkIfValidTitle(false);
      } else if (lien.length > 30) {
        setShowLoader(false);
        checkIfvalid_link(false);
      } else {
        if (type_event === "private") {
          res = await create_event(data);
          if (res.data && res.data.success) {
            makeAvailabilities();
            let responePostAvaibilities = await send(userObject.id, res.data.data.id);
            if (responePostAvaibilities && responePostAvaibilities.data.success) {
              history.push({
                pathname: "/user/me/calendar",
                state: {
                  id_event: res.data.data.id,
                  id_avaibilities: responePostAvaibilities && responePostAvaibilities.data.data.id,
                },
              });
            }
          }
        } else {
          res = await create_event_groupe(data);
          makeAvailabilities();
          let responePostAvaibilities = await send(userObject.id, res.data.data.id);
          if (responePostAvaibilities && responePostAvaibilities.data.success) {
            history.push({
              pathname: "/user/me/calendar",
              state: {
                id_event: res.data.data.id,
                id_avaibilities:
                  responePostAvaibilities &&
                  responePostAvaibilities.data.data.id,
              },
            });
          }
        }
        setShowLoader(false);
      }
    }
  };

  const send = async (user_id, event_id) => {
    let data = {
      user_id,
      event_id,
      availabilities: availabilities,
      duration: "60",
    };
    return await post_disponibilityes(data);
  };

  return (
    <>
      <Header />
      <div className="container-root-form">
        <div className="container-form-create">
          <div className="container-title-liste">
            <div className="btn-back-form" onClick={backToGroupeType}>
              <NavigateBefore color="primary" style={{ color: "#00a2ff" }} />
              <span className="txt-new-event" style={{ color: "#00a2ff" }}>
                {t("form_event_create.back")}
              </span>
            </div>
            <div className="txt-add-type">
              {t("form_event_create.title")}
              {""}
              {type_event === "private"
                ? t("accueil.private")
                : t("accueil.groupe")}
            </div>
            <div
              className="type-of-event"
              style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}
            >
              {/* {t("form_event_create.type")} */}
            </div>
          </div>
          <div className="line-create"/>
          <div className="container-formulaire">
            <div className="content-border">
              <div className="colom-row-style">
                <div className="rounder-bouton"/>
                <span style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}>
                  {t("form_event_create.what_event")}
                </span>
              </div>
              <div className="colom-row-style-rigth">
                <div
                  className="btn-next"
                  onClick={() => {create_groupe(userObject && userObject.id, name_event, valueLink, description, lieu, color, nombre_inviter)}}
                >
                  <span
                    style={{
                      fontFamily: "Proxima Nova",
                      fontWeight: "bold",
                      display: showLoader ? "none" : "flex",
                    }}
                  >
                    {t("form_event_create.suivant")}
                  </span>
                  <Loader style={{ display: showLoader ? "flex" : "none" }} />
                </div>
                <span
                  className="reset-btn"
                  style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}
                  onClick={() => backToGroupeType()}
                >
                  {t("form_event_create.annuler")}
                </span>
              </div>
            </div>
            <div className="line-unde-menu"/>
            <div className="container-form-text">
              <div className="txt-title-name">
                {t("form_event_create.event_name")}
                <span
                  style={{
                    fontSize: "small",
                    marginTop: "5vh",
                    color: "red",
                    display: is_valid_title ? "none" : "flex",
                  }}
                >
                  {t("form_event_create.mes_error_title")}
                </span>
              </div>
              <div className="container-name-event">
                <input
                  type="text"
                  className="txt-event"
                  placeholder="Event Name"
                  id="0.1250485974712231034"
                  value={name_event}
                  onChange={handle_event_name}
                />
              </div>
              <div className="txt-title-lieu">
                {t("form_event_create.type_rdv")}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Radio
                  checked={selectedValue === "Physique"}
                  onChange={handleChange}
                  value="Physique"
                  color="default"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "D" }}
                />
                <span
                  style={{
                    fontFamily: "Proxima Nova",
                    color: "#4d5055",
                    fontSize: "medium",
                  }}
                >
                  {t("form_event_create.physique")}
                </span>
                <Radio
                  checked={selectedValue === "a distance"}
                  onChange={handleChange}
                  value="a distance"
                  color="default"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "D" }}
                />
                <span
                  style={{
                    fontFamily: "Proxima Nova",
                    color: "#4d5055",
                    fontSize: "medium",
                  }}
                >
                  {t("form_event_create.distance")}
                </span>
              </div>
              <div
                className="txt-title-lieu-type"
                style={{
                  display: selectedValue === "Physique" ? "flex" : "none",
                }}
              >
                {t("form_event_create.lieu")}
              </div>
              <div
                className="container-name-event"
                style={{
                  display: selectedValue === "Physique" ? "flex" : "none",
                }}
              >
                <input
                  type="text"
                  className="txt-event"
                  placeholder="Adresse"
                  id="0.12559747231034"
                  value={lieu}
                  onChange={handleLieux}
                />
              </div>
              <div
                className="txt-title-lieu-type"
                style={{
                  display: selectedValue === "a distance" ? "flex" : "none",
                }}
              >
                A distance
              </div>
              <div
                className="container-name-event"
                style={{
                  display: selectedValue === "a distance" ? "flex" : "none",
                }}
              >
                <input
                  type="text"
                  className="txt-event"
                  placeholder={t("form_event_create.placeholder_distance")}
                  id="0.12559747231034"
                  value={lieu}
                  onChange={handleLieux}
                />
              </div>
              <div className="txt-title-lieu">
                {" "}
                {t("form_event_create.des")}
              </div>
              <div className="container-name-event-area">
                <textarea
                  className="txt-event-area"
                  value={description}
                  onChange={handleDescription}
                />
              </div>
              <div className="txt-title-lieu">
                {t("form_event_create.link")}
                <span
                  style={{
                    fontSize: "small",
                    marginTop: "5vh",
                    color: "red",
                    display: isvalid_link ? "none" : "flex",
                  }}
                >
                  {t("form_event_create.mes_error_lien")}
                </span>
              </div>
              <div
                style={{
                  marginTop: "3vh",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginRight: "20px",
                    fontFamily: "Proxima Nova",
                    fontSize: "bold",
                    color: "#4d5055",
                  }}
                >
                  ListooRDV.com/{pseudoLink}/
                </span>
                <div className="container-name-event-form-create">
                  <input
                    type="text"
                    className="txt-event-lien"
                    placeholder="lien évènement"
                    id="0.12504859747034"
                    value={lien}
                    onChange={handleLien}
                    onBlur={makeLink}
                  />
                </div>
              </div>
              <div
                className="txt-title-number"
                style={{
                  display: type_event === "private" ? "none" : "flex",
                  marginTop: "5vh",
                }}
              >
                {t("form_event_create.nbr")}
              </div>
              <div
                className="container-name-event-number"
                style={{ display: type_event === "private" ? "none" : "flex" }}
              >
                <input
                  type="number"
                  min="1"
                  className="txt-event-number"
                  placeholder=""
                  id="0.125048597471332231034"
                  value={nombre_inviter}
                  onChange={handle_Nbr_event}
                />
              </div>

              <div className="txt-title-lieu">
                {t("form_event_create.event_color")}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "4vh",
                }}
              >
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#8989fc",
                    borderRadius: "50%",
                    fontFamily: "Proxima Nova",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => setColor("#8989fc")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#8989fc" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#FF0000",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#FF0000")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#FF0000" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#F778B4",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#F778B4")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#F778B4" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#4A91E9",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#4A91E9")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#4A91E9" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#2CC0D7",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#2CC0D7")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#2CC0D7" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#34C76E",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#34C76E")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#34C76E" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#67C820",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#67C820")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#67C820" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#DFC12E",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#DFC12E")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#DFC12E" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#F49A30",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#F49A30")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#F49A30" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginRight: "1.5vw",
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#A4A230",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Proxima Nova",
                  }}
                  onClick={() => setColor("#A4A230")}
                >
                  <CheckIcon
                    style={{
                      display: color === "#A4A230" ? "flex" : "none",
                      color: "white",
                      height: "40px",
                      fontSize: "bold",
                    }}
                  />
                </div>
              </div>
              <div className="availability-title">
                {t("form_event_create.availability")}
              </div>
              <div className="txt-title-lieu">
                {t("form_event_create.monday")}
              </div>
              <div className="row m-1">
                {intervalClick && intervalClick.monday.map((t, index) => {
                  return (
                    <div key={index}>
                      <TextField
                        id="time"
                        label="De"
                        type="time"
                        defaultValue={t.from}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeFrom(index, event, 'monday')}
                      />
                      <TextField
                        id="time"
                        label="A"
                        type="time"
                        defaultValue={t.to}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeTo(index, event, 'monday')}
                      />
                      <Button style={{}} onClick={() => remove_intervale(index, 'monday')}>
                        <DeleteIcon />
                      </Button>
                    </div>
                  );
                })}
                <p
                  id="spring-modal-description"
                  onClick={() => makeForm('monday')}
                  style={{
                    width: "17vw",
                    fontSize: "medium",
                  }}
                >
                  <AddIcon />
                  Nouvel Intervalle
                </p>
              </div>
              <div className="txt-title-lieu">
                {t("form_event_create.tuesday")}
              </div>
              <div className="row m-1">
                {intervalClick && intervalClick.tuesday.map((t, index) => {
                  return (
                    <div key={index}>
                      <TextField
                        id="time"
                        label="De"
                        type="time"
                        defaultValue={t.from}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeFrom(index, event, 'tuesday')}
                      />
                      <TextField
                        id="time"
                        label="A"
                        type="time"
                        defaultValue={t.to}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeTo(index, event, 'tuesday')}
                      />
                      <Button style={{}} onClick={() => remove_intervale(index, 'tuesday')}>
                        <DeleteIcon />
                      </Button>
                    </div>
                  );
                })}
                <p
                  id="spring-modal-description"
                  onClick={() => makeForm('tuesday')}
                  style={{
                    width: "17vw",
                    fontSize: "medium",
                  }}
                >
                  <AddIcon />
                  Nouvel Intervalle
                </p>
              </div>
              <div className="txt-title-lieu">
                {t("form_event_create.wednesday")}
              </div>
              <div className="row m-1">
                {intervalClick && intervalClick.wednesday.map((t, index) => {
                  return (
                    <div key={index}>
                      <TextField
                        id="time"
                        label="De"
                        type="time"
                        defaultValue={t.from}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeFrom(index, event, 'wednesday')}
                      />
                      <TextField
                        id="time"
                        label="A"
                        type="time"
                        defaultValue={t.to}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeTo(index, event, 'wednesday')}
                      />
                      <Button style={{}} onClick={() => remove_intervale(index, 'wednesday')}>
                        <DeleteIcon />
                      </Button>
                    </div>
                  );
                })}
                <p
                  id="spring-modal-description"
                  onClick={() => makeForm('wednesday')}
                  style={{
                    width: "17vw",
                    fontSize: "medium",
                  }}
                >
                  <AddIcon />
                  Nouvel Intervalle
                </p>
              </div>
              <div className="txt-title-lieu">
                {t("form_event_create.thursday")}
              </div>
              <div className="row m-1">
                {intervalClick && intervalClick.thursday.map((t, index) => {
                  return (
                    <div key={index}>
                      <TextField
                        id="time"
                        label="De"
                        type="time"
                        defaultValue={t.from}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeFrom(index, event, 'thursday')}
                      />
                      <TextField
                        id="time"
                        label="A"
                        type="time"
                        defaultValue={t.to}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeTo(index, event, 'thursday')}
                      />
                      <Button style={{}} onClick={() => remove_intervale(index, 'thursday')}>
                        <DeleteIcon />
                      </Button>
                    </div>
                  );
                })}
                <p
                  id="spring-modal-description"
                  onClick={() => makeForm('thursday')}
                  style={{
                    width: "17vw",
                    fontSize: "medium",
                  }}
                >
                  <AddIcon />
                  Nouvel Intervalle
                </p>
              </div>
              <div className="txt-title-lieu">
                {t("form_event_create.friday")}
              </div>
              <div className="row m-1">
                {intervalClick && intervalClick.friday.map((t, index) => {
                  return (
                    <div key={index}>
                      <TextField
                        id="time"
                        label="De"
                        type="time"
                        defaultValue={t.from}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeFrom(index, event, 'friday')}
                      />
                      <TextField
                        id="time"
                        label="A"
                        type="time"
                        defaultValue={t.to}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeTo(index, event, 'friday')}
                      />
                      <Button style={{}} onClick={() => remove_intervale(index, 'friday')}>
                        <DeleteIcon />
                      </Button>
                    </div>
                  );
                })}
                <p
                  id="spring-modal-description"
                  onClick={() => makeForm('friday')}
                  style={{
                    width: "17vw",
                    fontSize: "medium",
                  }}
                >
                  <AddIcon />
                  Nouvel Intervalle
                </p>
              </div>
              <div className="txt-title-lieu">
                {t("form_event_create.saturday")}
              </div>
              <div className="row m-1">
                {intervalClick && intervalClick.saturday.map((t, index) => {
                  return (
                    <div key={index}>
                      <TextField
                        id="time"
                        label="De"
                        type="time"
                        defaultValue={t.from}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeFrom(index, event, 'saturday')}
                      />
                      <TextField
                        id="time"
                        label="A"
                        type="time"
                        defaultValue={t.to}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeTo(index, event, 'saturday')}
                      />
                      <Button style={{}} onClick={() => remove_intervale(index, 'saturday')}>
                        <DeleteIcon />
                      </Button>
                    </div>
                  );
                })}
                <p
                  id="spring-modal-description"
                  onClick={() => makeForm('saturday')}
                  style={{
                    width: "17vw",
                    fontSize: "medium",
                  }}
                >
                  <AddIcon />
                  Nouvel Intervalle
                </p>
              </div>
              <div className="txt-title-lieu">
                {t("form_event_create.sunday")}
              </div>
              <div className="row m-1">
                {intervalClick && intervalClick.sunday.map((t, index) => {
                  return (
                    <div key={index}>
                      <TextField
                        id="time"
                        label="De"
                        type="time"
                        defaultValue={t.from}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeFrom(index, event, 'sunday')}
                      />
                      <TextField
                        id="time"
                        label="A"
                        type="time"
                        defaultValue={t.to}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        onChange={(event) => changeTo(index, event, 'sunday')}
                      />
                      <Button style={{}} onClick={() => remove_intervale(index, 'sunday')}>
                        <DeleteIcon />
                      </Button>
                    </div>
                  );
                })}
                <p
                  id="spring-modal-description"
                  onClick={() => makeForm('sunday')}
                  style={{
                    width: "17vw",
                    fontSize: "medium",
                  }}
                >
                  <AddIcon />
                  Nouvel Intervalle
                </p>
              </div>
              <div
                className="txt-title-number"
                style={{marginTop: "5vh"}}
              >
                {t("form_event_create.event_week")}
              </div>
              <div
                className="container-name-event-number"
              >
                <input
                  type="number"
                  min="1"
                  className="txt-event-number"
                  placeholder=""
                  id="0.125048597471452231034"
                  value={eventMonth}
                  onChange={(event) => setEventMonth(event.target.value)}
                />
              </div>
              <div className="colom-row-style-rigth">
                <div
                  className="btn-next"
                  onClick={() => {
                    create_groupe(
                      userObject && userObject.id,
                      name_event,
                      valueLink,
                      description,
                      lieu,
                      color,
                      nombre_inviter
                    );
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Proxima Nova",
                      fontWeight: "bold",
                      display: showLoader ? "none" : "flex",
                    }}
                  >
                    {t("form_event_create.suivant")}
                  </span>
                  <Loader style={{ display: showLoader ? "flex" : "none" }} />
                </div>
                <span
                  className="reset-btn"
                  style={{ fontFamily: "Proxima Nova", fontWeight: "bold" }}
                  onClick={() => backToGroupeType()}
                >
                  {t("form_event_create.annuler")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
