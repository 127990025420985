// Accueil page , liste event
import React, { useState, useEffect } from "react";
import "../../style/accueil/accueil.css";
import Event from "./event";
import Header from "../_common/header";
import Avatar from "@material-ui/core/Avatar";
import PlusOne from "@material-ui/icons/PlusOne";
import { useAuth } from "../../context/auth";
import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import { useTranslation } from "react-i18next";
import mapDispatchToProps from "../../redux/mapDispatchToProps";
import { ReactComponent as No_event } from "../../style/resource/not_event.svg";
import { useHistory, Redirect } from "react-router-dom";
import { ReactComponent as Chargement } from "../../style/resource/loader_white.svg";
import { ReactComponent as ChargementSmall } from "../../style/resource/loader_small.svg";
import moment from "moment";
import ConfirmDialog from "../_common/confirmDialog";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "react-tabs/style/react-tabs.css";
import {
  getMyEventAvenir,
  getMyEventCurrent,
} from "../../service/backEndService";
import ListEvent from "../components/listEvent";

const PROFIL_PIC_URL = process.env.REACT_APP_PROFIL_PIC_URL;

function Accueil(props) {
  const { userObject, setUserObject, data } = useAuth();
  const history = useHistory();
  const [tab, showTab] = useState("list-event");
  const [isLoggedIn, setIsLogginn] = useState(userObject && userObject.id);
  const [checked, setChecked] = React.useState(true);
  const [loader, setLoader] = React.useState(false);
  const [loaderSmall, setLoaderSmall] = React.useState(false);
  const [loaderSmallTwo, setLoaderSmallTwo] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEvent, setEvent] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [eventAvenir, setEventAvenir] = React.useState(null);
  const [eventCurrent, setEventCurrent] = React.useState(null);
  const [current_copy, setCopie] = React.useState("");
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [user_log, setIsLog] = useState(localStorage.getItem("user_log"));

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return width;
    // return {
    //   width,
    //   height,
    // };
  }

  function showConfirmDialog(e) {
    setOpenDialog(true);
    setEvent(e.currentTarget.getAttribute("eventid"));
  }

  const closeConfirmDialog = function () {
    setOpenDialog(false);
    setEvent(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose_modal = () => {
    setOpen(false);
  };

  const getEventAvenirFunction = async () => {
    setLoaderSmallTwo(true);
    let res = await getMyEventAvenir(userObject.id);
    if (res && res.data.success) {
      setEventAvenir(res.data && res.data.data);
      setLoaderSmallTwo(false);
    }
  };

  const remove_status = (res) => {
    handleClickOpen();
  };

  const handleDelete = async (e) => {
    closeConfirmDialog();
    const data = {
      user_id: userObject.id,
      event_id: selectedEvent,
    };
    await props.deleteAnEvent(data, remove_status);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function create_event() {
    history.push("/user/me/create_groupe");
  }

  async function toogleTab(e) {
    showTab(e.currentTarget.getAttribute("data-toogle"));
    let events = e.currentTarget.getAttribute("class");
    if (events === "tab_type_bar_bottom_events") {
      setLoaderSmall(true);
      let res = await getMyEventCurrent(userObject.email);
      if (res && res.data.success) {
        var dateTime_remove = moment(new Date(), "MM-DD-YYYY HH:MM").add(
          1,
          "hour"
        );

        let event_current_filter =
          res.data &&
          res.data.data.filter((el) => {
            let event_date = moment(
              new Date(el.subscriber.date + " " + el.subscriber.hour_end),
              "MM-DD-YYYY HH:MM"
            );
            if (moment(event_date).isAfter(dateTime_remove)) {
              return el;
            }
          });

        setEventCurrent(event_current_filter);
        setLoaderSmall(false);
      }
    } else {
      setLoaderSmallTwo(false);
      setLoaderSmall(false);
    }
  }

  function logout() {
    setUserObject({});
    history.push("/user/me/login");
  }

  function handleOnCopy(link) {
    setCopie(link);
  }

  useEffect(() => {
    async function fetchMyAPI() {
      setLoader(true);
      await props.getEvent(userObject.id);
      setLoader(false);
    }
    fetchMyAPI();
    // return () => {};
  }, []);

  const navToDashboard = (mail) => {
    let url = window.location.origin + "/" + mail;
    window.open(url);
  };

  const navigateToreserve = (link_to_event) => {
    let base_url = window.location.origin;
    let url = userObject && userObject.profil.link + "/" + link_to_event;
    window.open(base_url + "/" + url);
  };

  const [userAvatar, setUserAvatar] = useState(
    PROFIL_PIC_URL + userObject.profil.picture_name
  );

  const modif_event = (event) => {
    history.push({
      pathname: "/user/me/modif_info_event",
      state: event,
    });
  };

  const modif_dispo = (event) => {
    history.push({
      pathname: "/user/me/modif_info_dispo",
      state: event,
    });
  };

  if (isLoggedIn === undefined || isLoggedIn === "undefined" || isLoggedIn === "" || user_log !== "OK") {
    return <Redirect to={"/user/me/login"} />;
  } else {
    return (
      <>
        <Header />
        <div className="container-root">
          <div className="body-container">
            <div className="body-header">
              <div className="info-user">
                <Avatar alt="Profile" src={userAvatar} />
                <div className="container-info-accueil">
                  <span className="txt-name-user">{userObject.username}</span>
                  <span className="txt-url">
                    <span
                      onClick={() =>
                        navToDashboard(userObject && userObject.profil.link)
                      }
                      //  to={"/" + userObject.email}
                    >
                      listoordv.com/{userObject && userObject.profil.link}
                    </span>
                  </span>
                </div>
              </div>
              <div className="home-control pull-right d-none d-sm-block">
                <div className="form-group"/>
                <button
                  className="containadd"
                  onClick={create_event}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#00a2ff",
                  }}
                >
                  <PlusOne style={{ color: "#ffffff", height: "16px" }} />
                  <span
                    className="txt-new-event"
                    style={{ fontWeight: "bold" }}
                  >
                    {t("accueil.new_event")}
                  </span>
                </button>
              </div>
              <div className="tab-view">
                <div
                  className="tab_type_bar_bottom"
                  onClick={toogleTab}
                  data-toogle="list-event"
                >
                  <span
                    className={
                      "txt-tab " + (tab === "list-event" ? "active" : "")
                    }
                  >
                    {t("accueil.type")}
                  </span>
                </div>
                <div
                  className="tab_type_bar_bottom_events"
                  data-toogle="scheduled-event"
                  onClick={toogleTab}
                >
                  <span
                    className={
                      "txt-tab " + (tab === "scheduled-event" ? "active" : "")
                    }
                  >
                    {t("accueil.event")}
                  </span>
                </div>
              </div>
            </div>
            <div className="container">
              <div
                className={"tab-list " + (tab === "list-event" ? "active" : "")}
              >
                <div
                  className={"loader " + (loader ? "" : "d-none")}
                  style={{ marginTop: "1vh" }}
                >
                  <Chargement />
                </div>
                <div className={loader ? "d-none" : "row"}>
                  {props.eventReducers &&
                  props.eventReducers.data.length > 0 ? (
                    props.eventReducers.data.map((element) => {
                      return (
                        <Event
                          modif_event={() => modif_event(element)}
                          modif_dispo={() => modif_dispo(element)}
                          owner={userObject}
                          eventid={element.id}
                          link={element.link}
                          navigateToreserve={() =>
                            navigateToreserve(element.link)
                          }
                          type={element.type}
                          color={element.color}
                          title={element.title}
                          duration={element.duration}
                          anchorEl={anchorEl}
                          handleClick={handleClick}
                          onDeleteClick={showConfirmDialog}
                          handleClose={handleClose}
                          current_copy={current_copy}
                          onCopy={handleOnCopy}
                        />
                      );
                    })
                  ) : (
                    <div>
                      <p style={{ color: "#616468" }}>{t("accueil.not_one")}</p>
                      <p style={{ color: "#616468", fontSize: "small" }}>
                        {t("accueil.not_two")}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={
                  "tab-list " + (tab === "scheduled-event" ? "active" : "")
                }
              >
                <div>
                  <div style={{ display: "none" }}>
                    <div>
                      <No_event style={{ alignSelf: "center" }} />
                    </div>
                    <div>
                      <span
                        style={{
                          fontFamily: "Proxima Nova",
                          fontWeight: "bold",
                        }}
                      >
                        {t("accueil.without_event_avenir")}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        marginTop: "15px",
                        flex: "row",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      <Tabs className="tab_liste">
                        <TabList>
                          <Tab
                            style={{
                              fontFamily: "Proxima Nova",
                              fontWeight: "bold",
                              fontSize:
                                getWindowDimensions() < 600
                                  ? "small"
                                  : "medium",
                            }}
                          >
                            {t("accueil.evt_encours")}
                          </Tab>
                          <Tab
                            style={{
                              fontFamily: "Proxima Nova",
                              fontWeight: "bold",
                            }}
                            onClick={() => getEventAvenirFunction()}
                          >
                            {t("accueil.evt_a_venir")}
                          </Tab>
                        </TabList>
                        <TabPanel style={{ width: "100vw" }}>
                          <div
                            style={{
                              alignSelf: "center",
                              marginTop:
                                getWindowDimensions() < 600 ? "30vw" : "0",

                              display: loaderSmall ? "flex" : "none",
                            }}
                          >
                            <ChargementSmall />
                          </div>
                          {eventCurrent && eventCurrent.length > 0 ? (
                            eventCurrent.map((el) => (
                              <div
                                style={{
                                  alignSelf: "center",
                                  display: loaderSmall ? "none" : "flex",
                                  width: "80vw",
                                }}
                              >
                                <ListEvent {...el} />
                              </div>
                            ))
                          ) : (
                            <div
                              className="no-content-wrapper"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: loaderSmall ? "none" : "flex",
                              }}
                            >
                              <div>
                                <No_event style={{ alignSelf: "center" }} />
                              </div>
                              <div>
                                <span
                                  style={{
                                    fontFamily: "Proxima Nova",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("accueil.without_event_encours")}
                                </span>
                              </div>
                            </div>
                          )}
                        </TabPanel>
                        {/* -------------------------------------------------------Panel Two------------------------------------ */}
                        <TabPanel>
                          <div
                            style={{
                              alignSelf: "center",
                              marginTop:
                                getWindowDimensions() < 600 ? "30vw" : "0",

                              display: loaderSmallTwo ? "flex" : "none",
                            }}
                          >
                            <ChargementSmall />
                          </div>
                          {eventAvenir && eventAvenir.length > 0 ? (
                            eventAvenir.map((el) => (
                              <div
                                style={{
                                  alignSelf: "center",
                                  display: loaderSmallTwo ? "none" : "flex",
                                  width:
                                    getWindowDimensions() < 600
                                      ? "100vw"
                                      : "80vw",
                                }}
                              >
                                <ListEvent {...el} />
                              </div>
                            ))
                          ) : (
                            <div
                              className="no-content-wrapper"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: loaderSmallTwo ? "none" : "flex",
                              }}
                            >
                              <div>
                                <No_event style={{ alignSelf: "center" }} />
                              </div>
                              <div>
                                <span
                                  style={{
                                    fontFamily: "Proxima Nova",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t("accueil.without_event_avenir")}
                                </span>
                              </div>
                            </div>
                          )}
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog
          open={openDialog}
          message={t("accueil.dialog_delete")}
          onYes={handleDelete}
          onNo={closeConfirmDialog}
        />
        <Dialog
          open={open}
          onClose={handleClose_modal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("accueil.title_error_rendevous")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("accueil.body_error_rendevous")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose_modal} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Accueil);
