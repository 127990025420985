import React, { useState, useCallback } from "react";
import { ButtonOutlined, Dropzone, Button, CroppedImage } from "../index";
import { Avatar, Dialog, Grid } from "@material-ui/core";
import getCroppedImg from "../cropImage";
import "./UploadPicture.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faSave, faCamera } from "@fortawesome/free-solid-svg-icons";

function UploadPicture(props) {
  const dropzoneId = props.id || "dropzone-area";
  const accept = props.accept || "image/jpg, image/jpeg, image/png";
  const multiple = props.multiple || false;
  const maxSize = props.maxSize || 200000;
  const aspect = props.aspect || 1;
  const buttonText = props.buttonText || "Upload";

  const [droppedImage, setDroppedImage] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleImageDropped = (file, base64) => {
    const result = {
      file: file,
      image: base64,
    };

    setDroppedImage(result);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSaveImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        droppedImage.image,
        croppedAreaPixels
      );
      const file = droppedImage["file"];
      const uploadedImage = {
        name: file["name"],
        type: file["type"],
        size: file["size"],
        base64: croppedImage,
      };
      if (props.onUploadImage) {
        props.onUploadImage(uploadedImage);
      }
    } catch (e) {
      console.error(e);
    }

    setDialogOpen(false);
  }, [croppedAreaPixels, droppedImage]);

  const handleUploadImage = () => {
    document.getElementById(dropzoneId).click();
  };

  return (
    <div className="app-upload-picture">
      <Dropzone
        id={dropzoneId}
        onChange={handleImageDropped}
        contents={null}
        accept={accept}
        multiple={multiple}
        maxSize={maxSize}
      />
      <ButtonOutlined onClick={handleUploadImage}>
        <FontAwesomeIcon icon={faCamera} className="mr-2" />
        {buttonText}
      </ButtonOutlined>
      <Dialog
        onClose={handleDialogClose}
        aria-labelledby="image-cropping"
        open={dialogOpen}
      >
        <div className="cropper-container">
          <CroppedImage
            image={droppedImage.image}
            onCropComplete={handleCropComplete}
            aspect={aspect}
          />
        </div>
        <div className="acount-button-wrapper">
          <Button
            className="button-md button-inverted"
            variant="outlined"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button className="button-md" onClick={handleSaveImage}>
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            Save
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default UploadPicture;
