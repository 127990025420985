import React, { useEffect, useState } from "react";
import "../../style/link/selectEvent.css";
import Box from "@material-ui/core/Box";
import ArrowBack from "@material-ui/icons/ArrowBack";
import WatchLater from "@material-ui/icons/WatchLater";
import LocationOn from "@material-ui/icons/LocationOn";
import NavigateNext from "@material-ui/icons/NavigateNext";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import Button from "@material-ui/core/Button";
import { useHistory, Redirect } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
//import "rc-time-picker/assets/index.css";
import TimePickerPanel from "rc-time-picker/lib/Panel";
import moment from "moment";
import "moment/locale/zh-cn";
import "moment/locale/en-gb";
import "moment/locale/fr";
import {
  getAvaibility,
  getAllUserSubscribe,
} from "../../service/backEndService";
import { useSpring, animated } from "react-spring";
import { Spring } from "react-spring/renderprops";
import { ReactComponent as Chargement } from "../../style/resource/loader_white.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function clickDecade(date) {
  let dateClick = date;
  console.log("clickDecade", moment(new Date(date)).format("MM/DD/YYYY"));
}

const queryString = require("query-string");

export const timeArray = ["00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30",
  "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30",
  "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"]

export default function SelectEvent(props) {
  const parsed = queryString.parse(props.location.search);

  console.log("Select_date_for_small", parsed);
  console.log("Select_date_for_small22", props);

  const paddingMin = {
    paddingLeft: "20px",
    paddingRight: "20px",
  };
  const paddingMax = {
    paddingLeft: "40px",
    paddingRight: "40px",
  };
  const [email, setEmail] = useState(
    props && props.match.params && props.match.params.email
  );
  const [link, setlink] = useState(
    props && props.match.params && props.match.params.link
  );

  const [data, setdata] = useState(); //DATA TABLEAUX ENROULLEMENT
  const [enroullement, setEnroullement] = useState(0); //NOMBRE ENROULLEMENT 60
  const [hour_enroullement, sethourEnroulement] = useState("");
  const [event, setEvent] = useState(""); //EVENT IN API
  const [duration, setDuration] = useState(""); // DURATION EVENT
  const [intervale_disp, setIntervaleDispo] = useState(); // INTERVALE IN ONE DATE
  const [dataClickFormatMoment, setDateClickFormatMomment] = useState(); //DATE CLICK TO COMPORE IF BETWEN (DD-MM-YY)

  const [clickDate, setClickDate] = useState(true); // DATE CLCIK
  const [avaible, setDateNotAvaible] = useState();
  const [item_click, setItemClick] = useState(false);
  const [loader, setLoader] = useState(true);

  const st = useSpring({ value: 100, from: { value: 0 } });
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const [ifram, setIfram] = React.useState(parsed && parsed.ifram);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose_modal = () => {
    setOpen(false);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return width;
    // return {
    //   width,
    //   height,
    // };
  }

  let dayOne = moment(new Date(data && data[0].date)).format(
    "DD.MM.YYYY HH:mm"
  );
  let dayTwo = moment(new Date()).format("DD.MM.YYYY HH:mm");

  useEffect(() => {
    console.log("PROPS", ifram);
    setLoader(true);
    let dateNow = moment(new Date()).format("DD-MM-YYYY");
    console.log("dateNow", dateNow);
    async function fetchMyAPI() {
      // setLoader(true);
      let res = await getAvaibility(email, link);
      let result = res.data;
      // setData(result.data);
      // setUserData(result.user);
      console.log("fetchMyAPI", result.data);
      if (result.data && result.data.length > 0) {
        setdata(result.data && result.data[0].availabilities);
        setEnroullement(
          result.data && result.data[0].availabilities.length - 1
        );
        setEvent(result.data && result.data[0].event);
        // setUser_avaibilite(result.data && result.data[0].user);
        setDuration(result.data && result.data[0].event.duration);

        let dateNowFoundObject =
          result.data &&
          result.data[0].availabilities &&
          result.data[0].availabilities.find(
            (element) => moment(element.date).format("DD-MM-YYYY") === dateNow
          );

        let allUserSubscribe = await getAllUserSubscribe(
          result.data[0].event && result.data[0].event.id,
          dateNow
        );
        console.log("responseresponse", allUserSubscribe);
        setIntervaleDispo(dateNowFoundObject && dateNowFoundObject.intervales);
        setDateNotAvaible(allUserSubscribe && allUserSubscribe.data.data);
        console.log("dateNowFoundObjectdateNowFoundObject", dateNowFoundObject);
      } else {
        console.log("res.data.length", res.data.length);
        history.push("/page/not/found");
      }
      setLoader(false);

      // setLoader(false);
    }
    fetchMyAPI();

    console.log("WIDTH AND HEIGTH", getWindowDimensions());
  }, []);

  async function clickDtae(date) {
    console.log("Valurrr", date);
    let dateClick = moment(new Date(date)).format("DD-MM-YYYY");
    let dateObjectFound = data.find(
      (element) => moment(element.date).format("DD-MM-YYYY") === dateClick
    );
    setDateClickFormatMomment(dateClick);
    setIntervaleDispo(dateObjectFound && dateObjectFound.intervales);
    let response = await getAllUserSubscribe(event.id, dateClick);
    console.log("responseresponse", response);
    setDateNotAvaible(response && response.data.data);
    console.log(
      "setIntervaleDispo",
      dateObjectFound && dateObjectFound.intervales
    );

    let a = getWindowDimensions();
    console.log(
      "setDateNotAvaiblesetDateNotAvaible",
      response && response.data.data
    );
    if (a < 600) {
      history.push({
        pathname: event.link + "/select_hour_for_mobile",
        state: {
          event,
          dataClickFormatMoment: moment(new Date(date)).format("DD-MM-YYYY"),
          intervale_disp: dateObjectFound && dateObjectFound.intervales,
          avaible: response && response.data.data,
          hour_enroullement,
          enroullement,
        },
      });
    } else {
      setClickDate(true);
    }
  }

  const checkIfDispo = (hour) => {
    // find if a hour debut est entre mon date de disponibilité
    let found =
      intervale_disp &&
      intervale_disp.find((el) => {
        if (hour === el.from || hour === el.to || moment(`12-01-2025 ${hour}`).isBetween(`12-01-2025 ${el.from}`, `12-01-2025 ${el.to}`)) {
          return true;
        }
      });
    console.log("AVAIBILITY", avaible);
    console.log("checkIfDispocheckIfDispocheckIfDispo", found);

    // detecter si la date de disponiblté n'est pas encore reservé
    if (found) {
      // avaible est la liste de reservation
      if (avaible && avaible.length > 0 && event.type === "event") {
        let f =
          avaible &&
          avaible.find((e) => {
            if (hour === e.hour || hour === e.hour_end || moment(`12-01-2025 ${hour}`).isBetween(`12-01-2025 ${e.hour}`, `12-01-2025 ${e.hour_end}`)) {
              console.log("ENTREEEE", e.hour);
              return true;
            }
          });
        return !f;
      } else if (avaible && avaible.length > 0 && event.type === "event_group") {
        let g =
          avaible &&
          avaible.find((e) => {
            if (hour === e.hour || hour === e.hour_end || moment(`12-01-2025 ${hour}`).isBetween(`12-01-2025 ${e.hour}`, `12-01-2025 ${e.hour_end}`)) {
              console.log(
                "avaible.lengthavaible.length",
                avaible.length,
                event.max_invite
              );
              return avaible.length === event.max_invite;
            }
          });
        return !g;
      } else {
        console.log("NOT RESERVERd");
        //event not reserver [avaible.length > 0 ]
        return true;
      }
    } //date not in my disponibility
    else return false;
  };

  const setHourDispo = (show, hour) => {
    setItemClick(true);
    sethourEnroulement(hour);
  };

  const showFormEvent = (data) => {
    console.log(`data, 12-01-2025 ${data}`);

    let data_with_hour = moment(`12-01-2025 ${data}`)
      .add(duration, "minutes")
      .format("HH:mm");

    let hour_debut = data;
    let hour_fin = data_with_hour;
    let event_id = event.id;
    let date_click = dataClickFormatMoment;
    console.log("data_with_hour", date_click, data_with_hour);
    history.push({
      pathname: event.link + "/form",
      state: {
        hour_debut,
        hour_fin,
        event_id,
        date_click,
        event,
        duration,
      },
    });
  };

  const checkIfDisable = (value) => {
    let date_value = moment(new Date(value.date)).format("DD-MM-YYYY");
    let dateObjectFound =
      data &&
      data.find(
        (element) => moment(element.date).format("DD-MM-YYYY") === date_value
      );
    return !!(dateObjectFound && dateObjectFound.intervales.length === []);
  };

  const checkDateIndex = () => {
    return moment(dayOne, "DD.MM.YYYY HH:mm").isBefore(
      moment(dayTwo, "DD.MM.YYYY HH:mm")
    );
  };

  console.log("MOMENT", checkDateIndex());
  return (
    <div className="container-select-event">
      <div
        className="container-selecter"
        style={{ marginTop: ifram ? "0px" : "9vh" }}
      >
        <Box
          boxShadow={getWindowDimensions() < 600 ? 0 : 2}
          bgcolor={getWindowDimensions() < 600 ? "transparent" : "white"}
          m={getWindowDimensions() < 600 ? 0 : 1}
          p={getWindowDimensions() < 600 ? 0 : 1}
          className="style_box"
          style={{
            width: ifram ? "100vw" : "",
          }}
        >
          <div style={{ display: loader ? "flex" : "none" }}>
            <Chargement />
          </div>
          <div
            className="content-select-calendar"
            style={{ display: loader ? "none" : "flex" }}
          >
            <div
              className="menu-left"
              style={{ marginLeft: ifram ? "0px" : "2vw" }}
            >
              <div className="btn-rounder-back2">
                {/* <ArrowBack
                  color="white"
                  fontSize="large"
                  style={{ color: "#22A2FF" }}
                /> */}
              </div>
              <div className="txt-name-selected">
                <span>{event && event.owner.username}</span>
              </div>
              <div className="txt-event-type-selected">
                <span>
                  événement en {event.type === "event" ? "privé" : "groupe"}
                </span>
              </div>
              <div className="hour-selected">
                <WatchLater
                  color="white"
                  fontSize="default"
                  style={{ color: "#949699", marginTop: "15px" }}
                />
                <span className="hour-event">
                  {duration === 60 ? "1 h" : duration + " min"}{" "}
                </span>
              </div>
              <div className="hour-selected">
                <LocationOn
                  color="white"
                  fontSize="default"
                  style={{ color: "#949699", marginTop: "15px" }}
                />
                <span className="hour-event">{event && event.location}</span>
              </div>
              <span className="txt-selected-name">
                {event && event.description}
              </span>
            </div>

            <div
              className="menu-rigth"
              style={{
                overflow: "hidden",
                marginLeft: "1vw",
                paddingLeft: "0px !important",
              }}
            >
              <div className="txt-date-selected">
                <span>Sélectionnez la date et l'heure</span>
              </div>
              <Calendar
                className="c1"
                // minDate={new Date(data && data[0].date)}
                minDate={new Date()}
                maxDate={new Date(data && data[enroullement].date)}
                locale={"fr"}
                showDoubleView={false}
                showWeekNumbers={false}
                prev2Label={() => <div/>}
                next2Label={() => <div/>}
                tileDisabled={(date) => checkIfDisable(date)}
                nextLabel={
                  <NavigateNext
                    color="white"
                    fontSize="large"
                    style={{ color: "#949699" }}
                  />
                }
                prevLabel={
                  <NavigateBefore
                    color="white"
                    fontSize="large"
                    style={{ color: "#949699" }}
                  />
                }
                onClickDay={clickDtae}
                // onClickDecade={clickDecade}
                navigationAriaLabel={"Next"}
                showNeighboringMonth={false}
              />
            </div>
            <div
              className="menu_rigth"
              style={{
                height: "70vh",
                marginLeft: "1vw",
                width: "20vw",
                flexDirection: "column",
                overflow: "scroll",
                marginTop: "10vh",
                display:
                  clickDate && getWindowDimensions() > 600 ? "flex" : "none",
              }}
            >
              {timeArray.map((item, index) => {
                return (
                    <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                        key={index}
                    >
                      <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            backgroundColor:
                                item_click && hour_enroullement === item
                                    ? "rgba(0, 0, 0, 0.6)"
                                    : "white",
                            color:
                                item_click && hour_enroullement === item
                                    ? "white"
                                    : "#22A2FF",
                            width: "20vw",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            border: "1px solid #22A2FF",
                            marginBottom: "10px",

                            fontSize: "medium",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            display: checkIfDispo(item) ? "flex" : "none",
                          }}
                          onClick={() => setHourDispo(true, item)}
                      >
                        {item}
                      </Button>
                      <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            width: "20vw",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            border: "1px solid #22A2FF",
                            marginBottom: "10px",
                            backgroundColor:
                                item_click && hour_enroullement === item
                                    ? "#22A2FF"
                                    : "white",
                            color:
                                item_click && hour_enroullement === item
                                    ? "white"
                                    : "#22A2FF",
                            marginLeft: "10px",
                            fontSize: "medium",
                            display:
                                item_click && hour_enroullement === item
                                    ? "flex"
                                    : "none",
                          }}
                          onClick={() => showFormEvent(item)}
                      >
                        {ifram ? "OK" : "Confirmer"}
                      </Button>
                    </div>
                );
              })}
            </div>
          </div>
        </Box>

        <Dialog
          open={open}
          onClose={handleClose_modal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {/* {t("accueil.title_error_rendevous")} */}
            Title
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* {t("accueil.body_error_rendevous")} */}
              Body
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose_modal} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
