import { getPublicData } from "./api";

import { baseUrl } from "./endpoints";

//const baseUrl = "http://apiagenda.loc"
const PageService = {
  getPolicyContent: function () {
    return getPublicData(baseUrl + "api/page/confidentialite");
  },
  getHomeContent: function () {
    return getPublicData(baseUrl + "api/page/home");
  },
}

export default PageService;
