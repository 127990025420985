// Create event with Group or Public
import React from "react";
import Header from "../_common/header";
import "../../style/accueil/create-event.css";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import IconButton from "@material-ui/core/IconButton";
import calandaricon from "../../../src/style/resource/planner-64.png";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { ReactComponent as Logo } from "../../style/resource/private.svg";
import { ReactComponent as LogoGroupe } from "../../style/resource/groupe.svg";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { useTranslation } from "react-i18next";

export default function CreateEvent() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { userObject, setUserObject } = useAuth();

  function backToAcceil() {
    history.goBack();
  }

  function create_form(type_groupe) {
    history.push({
      pathname: "/user/me/form_event_create",
      state: type_groupe,
    });
  }

  function logout() {
    setUserObject({});
    history.push("/user/me/login");
  }

  return (
    <>
      <Header />
      <div className="container-create">
        <div className="container-create">
          <div className="title-create">
            <div className="btn-retour-container">
              <div className="btn-back" onClick={backToAcceil}>
                <NavigateBefore
                  color="primary"
                  style={{
                    color: "#00a2ff",
                    alignSelf: "center",
                  }}
                />
                <span className="txt-new-event" style={{ color: "#00a2ff" }}>
                  {t("create_groupe.back")}
                </span>
              </div>
              <div className="title-new">{t("create_groupe.title")}</div>
            </div>
          </div>
          <div className="line-create"></div>
          <div className="choix-type-groupe">
            <div className="container-choix-one">
              <div className="groupe-image">
                <div className="im-container">
                  <Logo />
                </div>
                <div className="txt-info-center">
                  <span className="txt-type">{t("create_groupe.priver")}</span>
                  <span className="description-Groupe">
                    {t("create_groupe.description_private")}
                  </span>
                </div>
              </div>

              <div
                className="btn-create"
                onClick={() => create_form("private")}
              >
                <span className="c">{t("create_groupe.cree")}</span>
              </div>
            </div>

            <div className="container-choix-two">
              <div className="groupe-image">
                <div className="im-container">
                  <LogoGroupe></LogoGroupe>
                </div>
                <div className="txt-info-center">
                  <span className="txt-type">{t("create_groupe.groupe")}</span>
                  <span className="description-Groupe">
                    {t("create_groupe.description_groupe")}
                  </span>
                </div>
              </div>
              <div className="btn-create">
                <span className="c" onClick={() => create_form("groupe")}>
                  {t("create_groupe.cree")}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <FullCalendar plugins={[dayGridPlugin]} initialView="dayGridMonth" /> */}
      </div>
    </>
  );
}
