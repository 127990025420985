import { getMyeventList, put_event } from "../../service/backEndService";
import EventService from "../../service/eventService";

export const DELETE_A_EVENT = "DELETE_A_EVENT";
export const GET_ALL_EVENT = "GET_ALL_EVENT";
export const MODIF_AN_EVENT = "MODIF_AN_EVENT";

export const getEvent = (id_user) => {
  return async (dispatch) => {
    const result = await getMyeventList(id_user);
    console.log("result value", result);
    if (result && result.data.success) {
      dispatch(success(result.data.data));
      return;
    } else {
      console.log("error");
    }
  };
  function success(data) {
    return { type: GET_ALL_EVENT, data };
  }
};

export const deleteAnEvent = (data, callback) => {
  return async (dispatch) => {
    const result = await EventService.deleteEvent(data);
    console.log("result value", data);
    console.log("EventService", result);

    if (result && result.data) {
      if (
        result.data.message == "Event allready reserved" &&
        result.data.success == false
      ) {
        callback(result.data.message);
        return;
      } else {
        dispatch(success(data.event_id));
        return;
      }
    } else {
      console.log("error");
    }
  };
  function success(data) {
    return { type: DELETE_A_EVENT, data };
  }
};

export const put_event_info = (data) => {
  return async (dispatch) => {
    const result = await put_event(data);
    console.log("result value", data);
    console.log("EventService", result);
    if (result && result.data) {
      dispatch(success(data));
      return;
    }
  };
  function success(data) {
    return { type: MODIF_AN_EVENT, data };
  }
};
