import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DoneIcon from "@material-ui/icons/Done";
import IconButton from "@material-ui/core/IconButton";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../context/auth";
import "../../style/accueil/event.css";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  typography: {
    padding: theme.spacing(1),
  },
}));

function Event(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  let base_url = window.location.origin;
  const { data } = useAuth();
  const [link_to_copie, setLink_to_copie] = React.useState(props && props.owner.profil.link + "/");
  const [current_copy, setCurentCopy] = React.useState(props.current_copy);
  const [anchorEl, setAnchorEl] = React.useState(props.anchorEl);

  React.useEffect(() => {
    setCurentCopy(props.current_copy);
  }, [props.current_copy]);

  React.useEffect(() => {
    setAnchorEl(props.anchorEl);
  }, [props.anchorEl]);

  const open = Boolean(anchorEl);
  const idEnrol = open ? "simple-popover" : undefined;

  const copie_link = (id) => {
    props.onCopy(id);
  };

  return (
    <div className="col-sm-4">
      <div
        className="items-one"
        style={{
          borderTop: `4px solid ${props.color}`,
        }}
        key={props.id}
      >
        <div className="row-param">
          <div className="ckeck-class" onClick={() => props.modif_dispo()}>
            <EventNoteIcon
              className="eventNoteIcon"
              defaultChecked
              color="default"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          <div className="items-one-control">
            <button
              className="delete-button"
              eventid={props.eventid}
              onClick={props.onDeleteClick}
            >
              <DeleteIcon className="event-control" />
            </button>
          </div>
        </div>
        <div
          className="txt-event-info"
          onClick={() => props.modif_event()}
        >
          <span className="txt-reunion">{props.title}</span>
          <span className="heure-info">
            {props.duration === 60 ? "1h " : props.duration + "min "}
            {props.type === "event_group"
              ? t("accueil.groupe")
              : t("accueil.private")}
          </span>
        </div>
        <div className="info-lien">
          <span
            className="txt-min"
            onClick={() => props.navigateToreserve()}
            style={{ fontSize: "small", alignSelf: "center" }}
          >
            /{props.link}
          </span>
          <CopyToClipboard
            text={base_url + "/" + link_to_copie + props.link}
            onCopy={copie_link}
          >
            <div className="btn-event">
              <div className="copy-link">{t("accueil.copie")}</div>
              <div className="modal-show">
                <div className="icon-show">
                  <IconButton
                    color="default"
                    aria-label="add"
                    size="small"
                    disabled="true"
                  >
                    <SendIcon
                      color="default"
                      style={{
                        color: "#00a2ff",
                        width: "2vw",
                        display:
                          current_copy !== base_url + "/" + link_to_copie + props.link || ""
                            ? "flex"
                            : "none",
                      }}
                    />
                    <DoneIcon
                      style={{
                        color: "#00a2ff",
                        width: "2vw",
                        display:
                          current_copy === base_url + "/" + link_to_copie + props.link
                            ? "flex"
                            : "none",
                      }}
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}

export default Event;
