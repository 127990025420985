import axios from "axios";
import { tokenBear } from "./token-bear";

const headers = () => ({
  "Content-Type": "application/json",
  "x-auth-api": "Bearer " + tokenBear,
  "Access-Control-Allow-Headers": "Content-Type",
  "Access-Control-Allow-Origin": "http://localhost:3000/",
  "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
});

const datas = (data, arg = "application/json") => {
  if (arg === "application/json") {
    return JSON.stringify(data);
  } else if (arg === "multipart/form-data") {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    console.log("formData =", JSON.stringify(formData));
    return formData;
  } else {
    return data;
  }
};

export const postAdataListoorRdv = async (url, data) => {
  console.log("postAdataListoorRdv", data);
  try {
    const res = await axios.post(url, datas(data), {
      headers: {
        "Content-Type": "application/json",
        "x-auth-api": "Bearer " + tokenBear,
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "http://localhost:3000/",
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,DELETE",
      },
    });
    return res;
  } catch (e) {
    console.log("[api.js], catch: ", e.message); // undefined
    return null;
  }
};

export const getAdata = async (url) => {
  try {
    const res = axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-api": "Bearer " + tokenBear,
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "http://localhost:3000/",
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,DELETE",
      },
    });
    return res;
  } catch (e) {
    console.log("[api.js], catch: ", e.message); // undefined
    return null;
  }
};

export const getPublicData = async (url) => {
  try {
    const res = axios.get(url, {
      headers: {
        "Content-Type": "application/json"
      },
    });
    return res;
  } catch (e) {
    console.log("[api.js], catch: ", e.message); // undefined
    return null;
  }
};

export const putAdata = async (url, data) => {
  return axios({
    method: "PUT",
    url: url,
    data: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-api": "Bearer " + tokenBear,
    },
  });
};

 export const deleteAdata = async (url, data) => {
  return axios({
    method: "DELETE",
    url: url,
    data: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-api": "Bearer " + tokenBear,
    },
  });
 };

// export const putPhoto = async (url, data, token, arg) => {
//   try {
//     const res = await axiosListoordv({
//       method: "put",
//       url,
//       data: datas(data, arg),
//       headers: headers(arg, token),
//     });
//     //console.log('res', res);
//     return res;
//   } catch (e) {
//     console.log("[api.js], catch: ", e.message); // undefined
//     return null;
//   }
// };
