import React from "react";
import not_found from "../style/resource/404.jpg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function Not_found() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const accueil = () => {
    history.push("/user/me/accueil");
  };
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <div
        style={{
          height: "1vh",
          backgroundColor: "#4E5158",
          width: "100vw",
          flexDirection: "column",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20vh",
        }}
      >
        <img src={not_found} style={{ height: "24vh", width: "15vw" }}></img>
        <span
          style={{
            fontWeight: "bold",
            fontFamily: "Proxima Nova",
            fontSize: "30px",
          }}
        >
          {t("404.not_found")}
        </span>
        <span
          style={{
            fontWeight: "bold",
            fontFamily: "Proxima Nova",
            fontSize: "18px",
          }}
        >
          {t("404.check_url_text_or")}
        </span>
        <div className="text-center mt-5">
          <button
            className="primary-btn"
            onClick={accueil}
            style={{ fontSize: "medium", width: "20vw" }}
          >
            {t("404.back_home")}{" "}
          </button>
        </div>
      </div>
    </div>
  );
}
