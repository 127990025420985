import React, { useState } from "react";
import "../../src/style/accueil/accueil.css";
import logo from "../../src/style/resource/logo.jpeg";

import { deepOrange, deepPurple } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));
export default function Header({ logout }) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);
  const [valueMargin, setValueMargin] = useState(0);
  const history = useHistory();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleMarggin = () => {
    setValueMargin(55);
  };

  const goProfil = () => {
    history.push("/user/me/profil");
  };

  const accueil = () => {
    history.push("/user/me/accueil");
  };

  const goToApiDoc = () => {
    window.open(process.env.REACT_APP_API_URL, "_blank");
  };

  return (
    <div>
      <div className="header-container">
        <div className="header">
          <div className="img-container" onClick={accueil}>
            <img src={logo} className="icon-calendar"></img>
          </div>
          <div className="menu-container">
            <div className="menu-one" onClick={accueil}>
              <span>Accueil</span>
            </div>
            <div className="menu-two" onClick={goToApiDoc}>
              <span>Integrations</span>
            </div>
            <div className="menu-three" onClick={goToApiDoc}>
              <span>Aide</span>
            </div>
            <div className="menu-four">
              <div className="dropdown" style={{ float: "right" }}>
                <span className="dropbtn">Compte</span>
                <div className="dropdown-content">
                  <a href="#" onClick={goProfil}>
                    Parametre du compte
                  </a>
                  <a href="#" onClick={logout}>
                    Deconnexion
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
