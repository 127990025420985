import React, { useState, useEffect } from "react";
import "../../style/accueil/calendar-file.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import calandaricon from "../../style/resource/24-hour-service.ico";
import calandaricon_blue from "../../style/resource/24-hour-service-blue.ico";
import moment from "moment";
import "moment/locale/zh-cn";
import "moment/locale/en-gb";
import interactionPlugin from "@fullcalendar/interaction";
import Header from "../header";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { modif_disponibility } from "../../service/backEndService";
import { useSpring, animated } from "react-spring/web.cjs";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import { ReactComponent as Loader } from "../../style/resource/loaderTreePoint.svg";
import { useTranslation } from "react-i18next";
import { getDispo60 } from "../../service/backEndService";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function Modif_info_dispo(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [stateOfLoader, setStateOfLoader] = React.useState(false);
  const [event_id, setEventId] = useState(props && props.location.state && props.location.state.id);
  const [id_avaibilities, setId_avaibilities] = useState();
  const [dateEnroll, setdateEnrolle] = useState([]);
  const [intervalClick, setIntervalClick] = useState();
  const [hours, setHours] = useState(Math.floor(props && props.location.state && props.location.state.duration / 60));
  const [minutes, setMinutes] = useState(props && props.location.state && props.location.state.duration % 60);
  const { t, i18n } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setIntervalClick("");
    setOpen(false);
  };

  useEffect(() => {
    async function fetchMyAPI() {
      let res = await getDispo60(event_id);
      setdateEnrolle(res && res.data.data[0].availabilities);
      setId_avaibilities(res && res.data.data[0].id);
    }
    fetchMyAPI();
  }, []);

  function handleDateClick(arg) {
    let dateDiff = Math.ceil(moment(arg.date).diff(moment(dateEnroll[0].date), 'days', true));
    let date_click = moment(arg.date).format("YYYY-MM-DD");
    let found = dateEnroll.find((t) => moment(t.date).format("YYYY-MM-DD") === date_click);
    if (found) {
      setIntervalClick(found);
    } else {
      setIntervalClick(
        {
          id: dateEnroll.length + dateDiff,
          date: moment(arg.date).format(),
          type: "date",
          intervales: [{
            from: "09:00",
            to: "17:00",
          }],
        }
      );
    }
    handleOpen();
  }

  const makeForm = () => {
    setIntervalClick((intervalClick) => ({
      ...intervalClick,
      intervales: [
        ...intervalClick.intervales,
        {
          from: "",
          to: "",
        },
      ],
    }));
  };

  const remove_intervale = (index) => {
    let data = intervalClick && intervalClick.intervales;
    let data_after_click = data.splice(index, 1);
    setIntervalClick((intervalClick) => ({
      ...intervalClick,
      intervales: [...data],
    }));
  };

  const changeFrom = (index_from, evt) => {
    let data = intervalClick && intervalClick.intervales;
    data[index_from].from = evt.target.value;

    setIntervalClick((intervalClick) => ({
      ...intervalClick,
      intervales: [...data],
    }));
  };

  const changeTo = (index_from, evt) => {
    let data = intervalClick.intervales;
    data[index_from].to = evt.target.value;
    setIntervalClick((intervalClick) => ({
      ...intervalClick,
      intervales: [...data],
    }));
  };

  const makeDisponible = () => {
    let found = dateEnroll.find((t) => moment(t.date).format("YYYY-MM-DD") === moment(intervalClick.date).format("YYYY-MM-DD"));
    let temp = dateEnroll;
    if (found) {
      let index_of = dateEnroll.indexOf(found);
      temp[index_of] = intervalClick;
    } else {
      temp.push(intervalClick)
    }
    setdateEnrolle(temp);
    handleClose();
  };

  const send = async () => {
    setStateOfLoader(true);
    let data = {
      availability_id: id_avaibilities,
      availabilities: dateEnroll,
      duration: parseInt(hours * 60, 10) + parseInt(minutes, 10),
    };
    let res = await modif_disponibility(data);
    if (res && res.data.success) {
      setStateOfLoader(false);
      history.push("/user/me/accueil");
    }
  };

  return (
    <div className="container-root-form-calendar">
      <div className={{ marginBottom: "10vh" }}>
        <Header />
      </div>
      <div className="border-view">
        <div className="container-header-calendar">
          {t("accueil.event_date_reservation_modif")}
        </div>
        <div className="container-header-calendar-text">
          <p
            style={{
              fontFamily: "12px Proxima Nova",
              fontSize: "14px",
            }}
          >
            {t("accueil.txt_event_durre")}
            {parseInt(hours * 60, 10) + parseInt(minutes, 10)}
            {t("accueil.txt_60")}
          </p>
          <div className="duration-box">
            <Grid container direction={"row"} spacing={5}>
              <Grid item>
                <TextField
                  id="hour-number"
                  label="Hours"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  className="duration-input"
                  value={hours}
                  onChange={(event) => setHours(event.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="min-number"
                  label="Minutes"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  className="duration-input"
                  value={minutes}
                  onChange={(event) => setMinutes(event.target.value)}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="container-calendar">
          <div
            style={{
              width: "70vw",
              marginBottom: "10vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontFamily: "14px Proxima Nova",
                fontSize: "16px",
                fontWeight: "bold",
                marginTop: "5vh",
              }}
            >
              {t("accueil.dispo")}
            </span>
            <span
              style={{
                fontFamily: "14px Proxima Nova",
                marginTop: "2vh",
                marginBottom: "4vh",
                fontSize: "16px",
              }}
            >
              {t("accueil.txt_avaibility")}
            </span>
            <FullCalendar
              locales="fr"
              dayHeaderClassNames="class_name_header"
              dayCellClassNames={(cell) => {
                return cell.isPast
                  ? "day_class_name_selected"
                  : "day_class_name";
              }}
              firstDay="1"
              monthMode={true}
              hiddenDays={[]}
              dayCellContent={(day) => {
                let dispo = dateEnroll.find((t) => {
                  return (moment(t.date).format("DD-MM-YYYY") === moment(day.date).format("DD-MM-YYYY"));
                });
                return (
                  <div
                    style={{
                      display: "flex",
                      width: "9vw",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      alignItems: "start",
                    }}
                  >
                    <div>
                      <span style={{ alignSelf: "flex-stat" }}>
                        {day.isToday ? "Aujourd'hui" : day.dayNumberText}
                      </span>
                    </div>
                    <div>
                      <img
                        style={{ width: "15px" }}
                        src={day.isPast ? calandaricon : calandaricon_blue}
                      ></img>
                    </div>
                    {dispo
                      ? dispo.intervales.map((t) => {
                          return <span>{t.from + "-" + t.to}</span>;
                        })
                      : ""}
                  </div>
                );
              }}
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              dateClick={handleDateClick}
            />
          </div>
          <Button
            onClick={() => send()}
            style={{
              border: "#00a2ff",
              backgroundColor: "#00a2ff",
              width: "15vw",
              marginBottom: "5vh",
              color: "white",
            }}
          >
            <div
              style={{
                width: "10vw",
                height: "6vh",
                display: stateOfLoader ? "flex" : "none",
              }}
            >
              <Loader />
            </div>
            <span style={{ display: stateOfLoader ? "none" : "flex" }}>
              Modifier
            </span>
          </Button>
        </div>
      </div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="spring-modal-title">Modifier la disponibilité</h2>
            {intervalClick &&
              intervalClick.intervales.map((t, index) => {
                return (
                  <div key={index}>
                    <TextField
                      id="time"
                      label="De"
                      type="time"
                      defaultValue={t.from}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      onChange={(event) => changeFrom(index, event)}
                    />
                    <TextField
                      id="time"
                      label="A"
                      type="time"
                      defaultValue={t.to}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      onChange={(event) => changeTo(index, event)}
                    />
                    <Button style={{}} onClick={() => remove_intervale(index)}>
                      <DeleteIcon />
                    </Button>
                  </div>
                );
              })}
            <p
              id="spring-modal-description"
              onClick={() => makeForm()}
              style={{
                width: "17vw",
                fontSize: "medium",
              }}
            >
              <AddIcon />
              Nouvel Intervalle
            </p>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                makeDisponible();
              }}
            >
              Appliquer la modification
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
