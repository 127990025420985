import {
  getEvent,
  deleteAnEvent,
  put_event_info,
} from "./events/events_action";

const mapDispatchToProps = (dispatch) => ({
  //////////////////////////////////////////////////////////////////////////
  // CHARGEMENT
  //////////////////////////////////////////////////////////////////////////
  getEvent: (payload) => dispatch(getEvent(payload)),
  put_event_info: (payload) => dispatch(put_event_info(payload)),

  deleteAnEvent: (payload, callback) =>
    dispatch(deleteAnEvent(payload, callback)),
});

export default mapDispatchToProps;
