import { combineReducers } from "redux";
import eventReducers from "../redux/events/events_reducer";

const appReducer = combineReducers({
  log: (_, action) => {
    return {};
  },
  eventReducers,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
