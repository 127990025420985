export const isValidEmail = (email) => {
  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (reg.test(email) === true) {
    return true;
  }
  return false;
};

export const suppr_acc_car_spec = function (a) {
  if (typeof a === "string") {
    var str = a;
    var tab_accent_brut =
      "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    var tab_sansAccent_brut =
      "aaaaaaaaaaaaooooooooooooeeeeeeeecciiiiiiiiuuuuuuuuynn";
    var tab_accent = tab_accent_brut.split("");
    var tab_sansAccent = tab_sansAccent_brut.split("");
    let tabCorrAcc = new Array();
    var i = -1;
    while (tab_accent[++i]) {
      tabCorrAcc[tab_accent[i]] = tab_sansAccent[i];
    }
    tabCorrAcc["Œ"] = "OE";
    tabCorrAcc["œ"] = "oe";
    str = str.replace(/./g, function ($0) {
      return tabCorrAcc[$0] ? tabCorrAcc[$0] : $0;
    });
    str = str.replace(/&amp;/g, "_");
    str = str.replace(/_amp;/g, "");
    str = str.replace(/&lt;/g, "_");
    str = str.replace(/_lt;/g, "_");
    str = str.replace(/&gt;/g, "_");
    str = str.replace(/_gt;/g, "_");
    str = str.replace(
      /(-| |#|"|@|:|\.|,|;|'|%|!|²|=|÷|\+|\?|\/|\[|\]|\{|\}|\*|\^|\$|\\|`|"|'|¨|€|£|¤|µ|§|~|ƒ|„|©|°|&)/g,
      "_"
    );
    return str;
  }
};

export const validateUrl = (value) => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
};
