import { postAdataListoorRdv, getAdata, putAdata } from "./api";

import { endpoint, baseUrl, baseUrlOnePlay } from "./endpoints";

const ProfilService = {
  getProfilInfos: function (profil_if) {
    return getAdata(baseUrl + endpoint.profil_infos + "?id=" + profil_if);
  },

  updateProfilInfos: function (data) {
    return putAdata(baseUrl + endpoint.profil_infos, data);
  },

  updateProfilPassword: function (data){
    return putAdata(baseUrl + endpoint.user_update_password ,data);
  },

  updateProfilPic: function (data){
    return putAdata(baseUrl + endpoint.profil_pic ,data);
  }

  
}

export default ProfilService;
