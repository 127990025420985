import React, { useEffect, useState } from "react";
import "../../style/link/form_select_event_styles.css";
import Box from "@material-ui/core/Box";
import ArrowBack from "@material-ui/icons/ArrowBack";

import WatchLater from "@material-ui/icons/WatchLater";
import LocationOn from "@material-ui/icons/LocationOn";
import NavigateNext from "@material-ui/icons/NavigateNext";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import Header from "./../_common/header";
//import "rc-time-picker/assets/index.css";
import TimePickerPanel from "rc-time-picker/lib/Panel";
import moment from "moment";
import momentTimeZone from "moment-timezone";

import "moment/locale/zh-cn";
import "moment/locale/en-gb";
import "moment/locale/fr";
import { getAvaibility } from "../../service/backEndService";
import { useSpring, animated } from "react-spring";
import { Spring } from "react-spring/renderprops";
import { ReactComponent as Chargement } from "../../style/resource/loader_white.svg";
import { useHistory, Redirect } from "react-router-dom";

import { subscribeInEvent } from "../../service/backEndService";
import { isValidEmail } from "../../utils/index";

export default function Form_select_event(props) {
  console.log("Form_select_eventForm_select_event", props);
  const paddingMin = {
    paddingLeft: "20px",
    paddingRight: "20px",
  };
  const paddingMax = {
    paddingLeft: "40px",
    paddingRight: "40px",
  };

  const [event, setEvent] = useState(
    props && props.location && props.location.state
  ); //EVENT IN API

  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [note, setNote] = useState("");
  const [stateOfForm, setForm] = useState(false);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return width;
    // return {
    //   width,
    //   height,
    // };
  }

  useEffect(() => {
    console.log("PROPS", event);
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const handleName = (evt) => {
    let name = evt.target.value;
    setName(name);
  };

  const handleEmail = (evt) => {
    let email = evt.target.value;
    setEmail(email);
  };

  const handlePhone = (evt) => {
    let phone = evt.target.value;
    setPhone(phone);
  };

  const handleNote = (evt) => {
    let note = evt.target.value;
    setNote(note);
  };
  const subscribe = async () => {
    let data = {
      event_id: event.event_id,
      name: name,
      email: email,
      phone: phone,
      note: note,
      timezone: momentTimeZone.tz.guess(),
      date: event.date_click,
      hour: event.hour_debut,
      hour_end: event.hour_fin,
    };
    if (isValidEmail(email)) {
      setLoader(true);

      let res = await subscribeInEvent(data);
      if (res && res.data.success) {
        setForm(true);
      }
      setLoader(false);

      console.log("subscribesubscribe", res);
    } else {
      alert("Veuillez verifier votre email");
    }
  };
  return (
    <>
      {/* <Header /> */}
      <div className="container-select-event">
        <div className="container-selecter">
          <Box
            boxShadow={getWindowDimensions() < 600 ? 0 : 2}
            bgcolor={getWindowDimensions() < 600 ? "transparent" : "white"}
            m={getWindowDimensions() < 600 ? 0 : 1}
            p={getWindowDimensions() < 600 ? 0 : 1}
            style={{ width: "80vw", borderRadius: "5px" }}
          >
            <div style={{ display: loader ? "flex" : "none" }}>
              <Chargement />
            </div>

            <div
              className="content-select-calendar row"
              style={{ display: stateOfForm || loader ? "none" : "flex" }}
            >
              <div className="menu-left col-sm-6">
                <div className="row">
                  <div className="col-3">
                    <div className="btn-rounder-back" onClick={() => goBack()}>
                      <ArrowBack
                        color="white"
                        fontSize="large"
                        style={{ color: "#22A2FF" }}
                      />
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="txt-name-selected ">
                      <span>
                        {event &&
                          event.event.owner &&
                          event.event.owner.username}
                      </span>
                    </div>
                  </div>

                  <div className="infos">
                    <div className="txt-event-type-selected">
                      <span>{event && event.event.title}</span>
                    </div>
                    <div className="hour-selected">
                      <WatchLater
                        color="white"
                        fontSize="default"
                        style={{ color: "#949699", marginTop: "15px" }}
                      />
                      <span className="hour-event">
                        {event && event.duration == "60"
                          ? "1 h"
                          : event.duration + "min"}
                      </span>
                    </div>
                    <div className="hour-selected">
                      <LocationOn
                        color="white"
                        fontSize="default"
                        style={{ color: "#949699", marginTop: "15px" }}
                      />
                      <span className="hour-event">
                        {event && event.event.location}
                      </span>
                    </div>
                    <div className="hour-selected">
                      <EventAvailableIcon
                        fontSize="default"
                        style={{ color: "#2DC29C", marginTop: "15px" }}
                      />
                      <span
                        className="hour-event"
                        style={{
                          fontSize: "medium",
                          color: "#2DC29C",
                          fontFamily: "Proxima Nova",
                          fontWeight: "bold",
                        }}
                      >
                        {event &&
                          event.hour_debut +
                            "-" +
                            event.hour_fin +
                            ", " +
                            moment(
                              event && event.date_click,
                              "DD-MM-YYYY"
                            ).format("dddd") +
                            ", " +
                            moment(
                              event && event.date_click,
                              "DD-MM-YYYY"
                            ).format("DD MMMM YYYY")}
                      </span>
                    </div>
                    <span className="txt-selected-name">
                      {event && event.event.description}
                    </span>
                  </div>
                </div>
              </div>

              <div className="menu-rigth col-sm-6" style={{}}>
                <div className="txt-date-selected">
                  <span>Indiquez vos informations</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "space-between",
                    marginLeft: "2vw",
                    marginTop: "2vh",
                  }}
                >
                  <TextField
                    className="name"
                    id="outlined-password-input"
                    label="Nom"
                    type="text"
                    autoComplete="current-password"
                    variant="outlined"
                    onChange={(txt) => handleName(txt)}
                  />
                  <TextField
                    className="email"
                    id="outlined-password-input"
                    label="Email"
                    type="text"
                    autoComplete="current-password"
                    variant="outlined"
                    onChange={(txt) => handleEmail(txt)}
                  />
                  <TextField
                    className="phone"
                    id="outlined-password-input"
                    label="Phone"
                    type="phone"
                    autoComplete="current-password"
                    variant="outlined"
                    onChange={(txt) => handlePhone(txt)}
                  />
                  <span style={{ marginTop: "4vh" }}>
                    Veuillez partager tout ce qui pourra être utile à la
                    préparation de notre réunion.
                  </span>

                  <TextField
                    className="input-control"
                    style={{ width: "25vw", marginTop: "1vh" }}
                    id="standard-multiline-flexible"
                    label=""
                    placeholder="Note"
                    multiline
                    rows={2}
                    onChange={(txt) => handleNote(txt)}
                  />
                </div>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginTop: "2vh", marginLeft: "2vw" }}
                  onClick={() => subscribe()}
                >
                  Valider{" "}
                </Button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10vh",
                marginBottom: "10vh",
                display: stateOfForm && loader == false ? "flex" : "none",
              }}
            >
              <span className="txt-date-selected">Confirmation</span>
              <span className="txt-event-type-selected">
                Votre rendez-vous a été bien enregistré
              </span>
              <div
                style={{
                  backgroundColor: "#949699",
                  width: getWindowDimensions() < 600 ? "90vw" : "40vw",
                  height: "2px",
                  marginTop: "2vh",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  alignSelf: "start",
                  flexDirection: "column",
                  marginLeft: getWindowDimensions() < 600 ? "0vw" : "20vw",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <WatchLater
                    color="white"
                    fontSize="default"
                    style={{ color: "#949699", marginTop: "15px" }}
                  />
                  <span className="hour-event">
                    {event && event.event.title}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <EventAvailableIcon
                    fontSize="default"
                    style={{ color: "#2DC29C", marginTop: "15px" }}
                  />
                  <span className="hour-event" style={{ color: "#2DC29C" }}>
                    {event &&
                      event.hour_debut +
                        "-" +
                        event.hour_fin +
                        ", " +
                        moment(event && event.date_click, "DD-MM-YYYY").format(
                          "dddd"
                        ) +
                        ", " +
                        moment(event && event.date_click, "DD-MM-YYYY").format(
                          "DD MMMM YYYY"
                        )}
                  </span>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TimelapseIcon
                    color="white"
                    fontSize="default"
                    style={{ color: "#2DC29C", marginTop: "15px" }}
                  />
                  <span className="hour-event" style={{ color: "#2DC29C" }}>
                    {event && event.duration == "60"
                      ? "1 h"
                      : event.duration + "min"}
                  </span>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <LocationOn
                    color="white"
                    fontSize="default"
                    style={{ color: "#949699", marginTop: "15px" }}
                  />
                  <span className="hour-event">
                    {event && event.event.location}
                  </span>
                </div>
              </div>
              <span className="txt-event-type-selected">
                Une invitation a été envoyée à votre adresse email
              </span>
              <div
                style={{
                  backgroundColor: "#949699",
                  width: getWindowDimensions() < 600 ? "90vw" : "40vw",
                  height: "2px",
                  marginTop: "2vh",
                }}
              ></div>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
}
