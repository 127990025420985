import React, { useState, useEffect } from "react";
import "../../../src/style/theme/style.css";
import "../../../src/style/theme/style.js";
import "../../../src/style/accueil/accueil.css";
import "../../../src/style/theme/css/style.css";
import { useAuth } from "../../context/auth";
import logo from "../../../src/style/resource/logo.jpeg";

import { deepOrange, deepPurple } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faUserCircle,
  faInfoCircle,
  faQuestionCircle,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));

const route_url = {
  fr: "https://fr.listoordv.com",
  en: "https://en.listoordv.com",
};
export default function Header(props, route) {
  const { t, i18n } = useTranslation();
  const { userObject, langue } = useAuth();
  const [isLoggedIn, setIsLoggin] = useState(userObject && userObject.id);
  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);
  const [valueMargin, setValueMargin] = useState(0);
  //pathname: "/user/me/register"
  const history = useHistory();
  const path = useLocation();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleMarggin = () => {
    setValueMargin(55);
  };

  const goProfil = () => {
    history.push("/user/me/profil");
  };

  const accueil = () => {
    if (
      (path && path.pathname == "/user/me/register") ||
      (path && path.pathname == "/user/me/login") ||
      (path && path.pathname == "/user/me/") ||
      (path && path.pathname == "/user/me/register-form-mail") ||
      (path && path.pathname == "/confidentialite") ||
      (path && path.pathname == "/")
    ) {
      //return;
      history.push("/");
    } else {
      history.push("/user/me/accueil");
    }
  };

  const newEvent = () => {
    history.push("/user/me/create_groupe");
  };

  const goToApiDoc = () => {
    window.open(
      t("type.langue") == "fr" ? route_url.fr : route_url.en,
      "_blank"
    );
  };

  let userMenu = (
    <>
      <li className={history.location.pathname == "/login" ? "active" : ""}>
        <a href="/user/me/register-form-mail">{t("header.get_started")}</a>
      </li>
      <li
        className={
          history.location.pathname == "/user/me/accueil" ? "active" : ""
        }
      >
        <a href="/user/me/login">{t("header.connexion")}</a>
      </li>
    </>
  );

  if (isLoggedIn) {
    userMenu = (
      <li
        className={
          history.location.pathname == "/user/me/profil" ? "active" : ""
        }
      >
        <a onClick={goProfil}>{t("header.my_account")}</a>
      </li>
    );
  }

  return (
    <header id="header" className="">
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <span className="logo">
          <img src={logo} className="icon-calendar"></img>
        </span>
        <nav className="nav-menu d-none d-lg-block">
          <ul>
            <li
              className={
                history.location.pathname == "/user/me/accueil" ? "active" : ""
              }
            >
              <a onClick={accueil}>{t("header.home")}</a>
            </li>
            <li>
              <a onClick={goToApiDoc}>{t("header.voir_plus")}</a>
            </li>
            {/* <li><a  onClick= {goToApiDoc}>{t('header.help')}</a></li> */}
            {userMenu}
            <li>
              <div className="lang-switcher">
                <boutton
                  className={
                    "lang-switcher-item " +
                    (i18n.language == "fr" ? "active" : "")
                  }
                  onClick={() => i18n.changeLanguage("fr")}
                >
                  Fr
                </boutton>
                <boutton
                  className={
                    "lang-switcher-item " +
                    (i18n.language == "en" ? "active" : "")
                  }
                  onClick={() => i18n.changeLanguage("en")}
                >
                  En
                </boutton>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <div
        className="container-fluid d-flex justify-content-between align-items-center d-block d-lg-none"
        id="mobile-footer-nav"
      >
        <div className="row">
          <div className="col-3 text-align-center">
            <a
              className={
                "menu-item " +
                (history.location.pathname == "/user/me/accueil"
                  ? "active"
                  : "")
              }
              onClick={accueil}
            >
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className="menu-item-icon"
                size="2x"
              />
            </a>
          </div>

          <div className="col-3 text-align-center">
            <a
              className={
                "menu-item " +
                (history.location.pathname == "/user/me/create_groupe"
                  ? "active"
                  : "")
              }
              onClick={newEvent}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="menu-item-icon"
                size="2x"
              />
            </a>
          </div>

          <div className="col-3 text-align-center">
            <a className="menu-item" onClick={goToApiDoc}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="menu-item-icon"
                size="2x"
              />
            </a>
          </div>

          <div className="col-3 text-align-center">
            <a
              className={
                "menu-item " +
                (history.location.pathname == "/user/me/profil" ? "active" : "")
              }
              onClick={goProfil}
            >
              <FontAwesomeIcon
                icon={faUserCircle}
                className="menu-item-icon"
                size="2x"
              />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
