export const baseUrl = process.env.REACT_APP_API_URL;
export const baseUrlOnePlay = "https://oneplay.dev.arkeup.com/";

export const endpoint = {
  check_email: "api/user/email_check",
  check_user_credantial: "api/user/login_check",
  create_user: "api/user",
  user_update_password: "api/user/update_password",
  create_profil: "api/profil",
  getProfilInfo: "api/profil/",
  create_event: "api/event",
  create_event_goupe: "api/event/group",
  get_event_info: "api/event/",
  get_list_event_info_link: "api/events/",
  put_an_event: "api/event",
  deleteEvent: "api/event",
  profil_infos: "api/profil",
  profil_pic: "api/profil/picture",
  valid_token: "api/user/activation",
  relance_email: "api/user/activation",
  diponibilitys: "api/availability",
  subscribe: "api/event/subscriber",
  getAllsubscribe: "api/subscriber",

  event_delete: "api/event",
  event_current: "api/event_current",
  upcomming: "api/event_upcomming",
  test: "match/matchcount",
};
