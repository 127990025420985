import React, { useEffect, useState } from "react";
import "../../style/link/allEvent-styles.css";
import Box from "@material-ui/core/Box";
import ArrowRight from "@material-ui/icons/ArrowRight";
import { getMyeventList_inLink } from "../../service/backEndService";
import { ReactComponent as Chargement } from "../../style/resource/loader_white.svg";
import { useHistory, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Avatar from "@material-ui/core/Avatar";

export default function AllEvent(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { handle } = props.match.params;
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState("");
  const [user_data, setUserData] = useState("");

  const [userAvatar, setUserAvatar] = useState(
    user_data && user_data.profil && user_data.profil.picture_name
  );
  const PROFIL_PIC_URL = process.env.REACT_APP_PROFIL_PIC_URL;

  useEffect(() => {
    async function fetchMyAPI() {
      setLoader(true);
      let res = await getMyeventList_inLink(handle);
      let result = res.data;
      if (!result.user) {
        history.push("/page/not/found");
      }
      console.log("useEffectuseEffect", result);
      setData(result.data);
      setUserData(result.user);
      setUserAvatar(result.user.profil);
      setLoader(false);
    }

    fetchMyAPI();
    return () => {};
  }, []);

  function showDisponibiliti(link) {
    history.push(user_data && user_data.profil.link + "/" + link);
  }
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return width;
    // return {
    //   width,
    //   height,
    // };
  }
  console.log("fdfdf", userAvatar.picture_name);

  return (
    <div className="content-my-event">
      <div className="container-link">
        <Box
          boxShadow={getWindowDimensions() < 600 ? 0 : 2}
          bgcolor={getWindowDimensions() < 600 ? "transparent" : "white"}
          m={getWindowDimensions() < 600 ? 0 : 1}
          p={getWindowDimensions() < 600 ? 0 : 1}
          style={{ width: "80vw", borderRadius: "5px" }}
        >
          <div style={{ display: loader ? "flex" : "none" }}>
            <Chargement />
          </div>
          <div
            className="container-float"
            style={{ display: loader ? "none" : "flex" }}
          >
            <div className="entete">
              <Avatar
                alt={user_data.username && user_data.username.toUpperCase()}
                src={PROFIL_PIC_URL + userAvatar.picture_name}
              />
              {/* <span className="name-title">{user_data.username}</span> */}
              <span className="name-title">{user_data.username}</span>
              <div
                style={{
                  display:
                    user_data && user_data.profil.description ? "none" : "flex",
                  flexDirection: "column",
                }}
              >
                <span className="msg-txt-small">
                  Bienvenue sur ma page de planification.
                </span>
                <span className="msg-txt-small">
                  Veuillez suivre les instructions pour ajouter
                </span>
                <span className="msg-txt-small">
                  un événement à mon calendrier.
                </span>
              </div>
              <span
                className="msg-txt-small"
                style={{
                  display:
                    user_data && user_data.profil.description ? "flex" : "none",
                }}
              >
                {user_data && user_data.profil.description}
              </span>
            </div>

            <div className="container-list-event-link">
              {data &&
                data.map((element) => {
                  return (
                    <div
                      className="one-container-list"
                      key={element.id}
                      onClick={() => showDisponibiliti(element.link)}
                    >
                      <div className="line-one-in-event"></div>
                      <div className="row-event-link">
                        <div
                          className="round-event"
                          style={{ backgroundColor: element.color }}
                        ></div>
                        <span className="title-event-link">
                          {element.title}
                        </span>
                        <ArrowRight style={{ height: "40px", width: "40px" }} />
                      </div>
                      <div className="txt-description-event">
                        <span
                          style={{
                            color: " rgba(77, 80, 85, 0.6)",
                            fontSize: "14px",
                            width: "5vw",
                          }}
                        >
                          {element.description}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}
