import React, { useEffect, useState } from "react";
import "../../style/link/select_date_for_small-styles.css";
import Box from "@material-ui/core/Box";
import ArrowBack from "@material-ui/icons/ArrowBack";
import WatchLater from "@material-ui/icons/WatchLater";
import LocationOn from "@material-ui/icons/LocationOn";
import NavigateNext from "@material-ui/icons/NavigateNext";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import Button from "@material-ui/core/Button";
import { useHistory, Redirect } from "react-router-dom";
import { browserHistory } from "react-router";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
//import "rc-time-picker/assets/index.css";
import TimePickerPanel from "rc-time-picker/lib/Panel";
import moment from "moment";
import "moment/locale/zh-cn";
import "moment/locale/en-gb";
import "moment/locale/fr";
import {
  getAvaibility,
  getAllUserSubscribe,
} from "../../service/backEndService";
import { useSpring, animated } from "react-spring";
import {timeArray} from "./selectEvent";

export default function Select_date_for_small(props) {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const paddingMin = {
    paddingLeft: "20px",
    paddingRight: "20px",
  };
  const paddingMax = {
    paddingLeft: "40px",
    paddingRight: "40px",
  };
  const [event, setEvent] = useState(
    props && props.location.state && props.location.state.event
  );

  const [duration, setDuration] = useState(
    props &&
      props.location.state &&
      props.location.state.event &&
      props.location.state.event.duration
  ); // DURATION EVENT

  const [data, setdata] = useState(); //DATA TABLEAUX ENROULLEMENT
  const [enroullement, setEnroullement] = useState(
    props && props.location.state && props.location.state.enroullement
  );
  // //NOMBRE ENROULLEMENT 60
  const [hour_enroullement, sethourEnroulement] = useState(
    props && props.location.state && props.location.state.hour_enroullement
  );
  //
  const [intervale_disp, setIntervaleDispo] = useState(
    props && props.location.state && props.location.state.intervale_disp
  );
  // // INTERVALE IN ONE DATE
  const [dataClickFormatMoment, setDateClickFormatMomment] = useState(
    props.location.state.dataClickFormatMoment
  );
  //props && props.location.state && props.location.state.dataClickFormatMoment

  // const [clickDate, setClickDate] = useState(
  //   props && props.location.state && props.location.state.clickDate
  // ); // DATE CLCIK
  const [avaible, setDateNotAvaible] = useState(
    props && props.location.state && props.location.state.avaible
  );
  const [item_click, setItemClick] = useState(false);

  const [loader, setLoader] = useState(true);

  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose_modal = () => {
    setOpen(false);
  };
  // setDateClickFormatMomment(
  //   props && props.location.state && props.location.state.dataClickFormatMoment
  // );
  console.log("eee", props.location.state.dataClickFormatMoment);
  const checkIfDispo = (hour) => {
    // find if a hour debut est entre mon date de disponibilité
    let found =
      intervale_disp &&
      intervale_disp.find((el) => {
        if (hour === el.from || hour === el.to || moment(`12-01-2025 ${hour}`).isBetween(`12-01-2025 ${el.from}`, `12-01-2025 ${el.to}`)) {
          return true;
        }
      });
    console.log("AVAIBILITY", avaible);
    // detecter si la date de disponiblté n'est pas encore reservé
    if (found) {
      // avaible est la liste de reservation
      if (avaible && avaible.length > 0 && event.type === "event") {
        let f =
          avaible &&
          avaible.find((e) => {
            if (hour === e.hour || hour === e.hour_end || moment(`12-01-2025 ${hour}`).isBetween(`12-01-2025 ${e.hour}`, `12-01-2025 ${e.hour_end}`)) {
              console.log("ENTREEEE", e.hour);
              return true;
            }
          });
        return !f;
      } else if (avaible && avaible.length > 0 && event.type === "event_group") {
        let g =
          avaible &&
          avaible.find((e) => {
            if (hour === e.hour || hour === e.hour_end || moment(`12-01-2025 ${hour}`).isBetween(`12-01-2025 ${e.hour}`, `12-01-2025 ${e.hour_end}`)) {
              console.log(
                "avaible.lengthavaible.length",
                avaible.length,
                event.max_invite
              );
              return avaible.length === event.max_invite;
            }
          });
        return !g;
      } else {
        //event not reserver [avaible.length > 0 ]
        return true;
      }
    } //date not in my disponibility
    else return false;
  };

  const setHourDispo = (show, hour) => {
    setItemClick(true);
    sethourEnroulement(hour);
  };

  const showFormEvent = (data) => {
    console.log(`data, 12-01-2025 ${data}`);

    let data_with_hour = moment(`12-01-2025 ${data}`)
      .add(duration, "minutes")
      .format("HH:mm");

    let hour_debut = data;
    let hour_fin = data_with_hour;
    let event_id = event.id;
    let date_click = dataClickFormatMoment;
    console.log("data_with_hour", date_click, data_with_hour);
    history.push({
      pathname: "small/form",
      state: {
        hour_debut,
        hour_fin,
        event_id,
        date_click,
        event,
        duration,
      },
    });
  };
  console.log("dateObjectFound", intervale_disp);

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="container-select-event">
      <div className="container-selecter">
        <div className="content-select-calendar" style={{ display: "flex" }}>
          <div className="menu-left">
            <div className="btn-rounder-back2">
              <div className="btn-rounder-back" onClick={() => goBack()}>
                <ArrowBack fontSize="large" style={{ color: "#22A2FF" }} />
              </div>
            </div>
            <div className="txt-name-selected">
              <span>{event && event.owner.username}</span>
            </div>
            {/* <div className="txt-event-type-selected">
              <span>
                événement en {event.type == "event" ? "privé" : "groupe"}
              </span>
            </div> */}
            <div className="hour-selected">
              <WatchLater
                color="white"
                fontSize="default"
                style={{ color: "#949699", marginTop: "15px" }}
              />
              <span className="hour-event">
                {duration === 60 ? "1 h" : duration + " min"}{" "}
              </span>
            </div>
            <div className="hour-selected">
              <LocationOn
                color="white"
                fontSize="default"
                style={{ color: "#949699", marginTop: "15px" }}
              />
              <span className="hour-event">{event && event.location}</span>
            </div>
            <span className="txt-selected-name">
              {event && event.description}
            </span>
          </div>

          <div
            className="menu_rigth"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "2vh",
              width: "80vw",
              height: "60vh",
              overflow: "auto",
              // display: clickDate ? "flex" : "none",
            }}
          >
              {timeArray.map((item, index) => {
                  return (
                      <div
                          style={{
                              display: "flex",
                              flexDirection: "row",
                          }}
                          key={index}
                      >
                          <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                  backgroundColor:
                                      item_click && hour_enroullement === item
                                          ? "rgba(0, 0, 0, 0.6)"
                                          : "white",
                                  color:
                                      item_click && hour_enroullement === item
                                          ? "white"
                                          : "#22A2FF",
                                  width: "80vw",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                  border: "1px solid #22A2FF",
                                  marginBottom: "10px",

                                  fontSize: "medium",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                  display: checkIfDispo(item) ? "flex" : "none",
                              }}
                              onClick={() => setHourDispo(true, item)}
                          >
                              {item}
                          </Button>
                          <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                  width: "80vw",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  border: "1px solid #22A2FF",
                                  marginBottom: "10px",
                                  backgroundColor:
                                      item_click && hour_enroullement === item
                                          ? "#22A2FF"
                                          : "white",
                                  color:
                                      item_click && hour_enroullement === item
                                          ? "white"
                                          : "#22A2FF",
                                  marginLeft: "10px",
                                  fontSize: "medium",
                                  display:
                                      item_click && hour_enroullement === item
                                          ? "flex"
                                          : "none",
                              }}
                              onClick={() => showFormEvent(item)}
                          >
                            Confirmer
                          </Button>
                      </div>
                  );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
