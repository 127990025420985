import { GET_ALL_EVENT, DELETE_A_EVENT, MODIF_AN_EVENT } from "./events_action";

const initialState = {
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EVENT:
      return {
        ...state,
        data: [...action.data],
      };
    case DELETE_A_EVENT:
      return {
        ...state,
        data: state.data.filter((e) => e.id != action.data),
      };
    case MODIF_AN_EVENT:
      const update_data =
        state &&
        state.data.map((elem) => {
          if (elem.id === action.data.event_id) {
            return Object.assign(elem, action.data);
          }
          return elem;
        });
      return {
        ...state,
        data: update_data,
      };
    default:
      return state;
  }
};
