import { postAdataListoorRdv, getAdata, putAdata , deleteAdata } from "./api";

import { endpoint, baseUrl, baseUrlOnePlay } from "./endpoints";

const EventService = {
 
   deleteEvent: function (data) {
    return deleteAdata(baseUrl + endpoint.event_delete, data);
  },
  
}

export default EventService;
