import React, { useState } from "react";
import "../../style/login/login-form-google.css";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import logo from "../../../src/style/resource/logo.jpeg";
import GoogleLogin from "react-google-login";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function LoginFormGoogle() {
  const history = useHistory();
  function showLogin() {
    history.push("/user/me/");
  }
  function navigateTocreateAcount() {
    history.push("/user/me/register");
  }
  const responseGoogle = (response) => {
    console.log("responseGoogle", response);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="container-header-google">
        <img src={logo} className="icon-rounded-logo"></img>
        <p className="title-login-google">
          Content de vous revoir, josemailaka@gmail.com !
        </p>
        <span className="not-me" onClick={showLogin}>
          (Ce n'est pas moi.)
        </span>
      </div>

      <div className="container-form-google">
        <GoogleLogin
          clientId="1032052224406-nij2fp593cth4upaletnrrn0omfiu8i9.apps.googleusercontent.com"
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              style={{
                backgroundColor: "#00a2ff",
                height: "8vh",
                border: "0px",
                borderRadius: "40px",
                width: "20vw",
                marginTop: "5vh",
                color: "white",
                fontFamily: "12vw, Proxima Nova",
                fontSize: "15px",
              }}
            >
              Connexion avec Google
            </button>
          )}
          buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
        />

        <div className="txt-with-acount">
          <span className="txt-mm">
            Vous n’avez pas de compte ?
            <span
              className="navig-create-acount-google"
              onClick={navigateTocreateAcount}
            >
              Inscrivez-vous.
            </span>
          </span>
        </div>
      </div>

      <div className="container-header-footer">
        <p className="title-langue">English</p>
        <ExpandMoreIcon style={{ color: "#00a2ff", width: "20px" }} />
      </div>
    </div>
  );
}
