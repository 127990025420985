import React, { useState, useEffect } from "react";
import Header from "./../_common/header";
import PageService from "../../service/pageService";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

export default function Confidentialite() {
  const { i18n } = useTranslation();
  const [policyContent, setPolicyContent] = useState("");
  useEffect(() => {
    PageService.getPolicyContent().then(function (response) {
      setPolicyContent(response.data.data);
    });
  }, [null]);

  return (
    <>
      <Header />
      <div className="container">
        {policyContent
          ? parse(
              i18n.language == "fr"
                ? policyContent.fr.content
                : policyContent.en.content
            )
          : ""}
      </div>
    </>
  );
}
