import React, { useEffect, useState } from "react";
import "../../style/login/register-styles.css";
import { useHistory } from "react-router-dom";
import logo from "../../../src/style/resource/logo.jpeg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { create_User } from "../../service/backEndService";
import { ReactComponent as Loader } from "../../style/resource/loaderTreePoint.svg";
import { useAuth } from "../../context/auth";
import eventHandler from "../../hooks/event_handler";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import Header from "./../_common/header";
import { useTranslation } from "react-i18next";
export default function Register(props) {
  const history = useHistory();
  const { userObject, setUserObject } = useAuth();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState(userObject.email);
  const [password, setPassword] = useState("");
  const [fullName, setFullname] = useState("");
  const [showError, setShowError] = useState(false);
  const [showError_name_vide, setShowError_name_vide] = useState(false);
  const [showError_password, setShowError_password] = useState(false);

  const [stateOfLoader, setStateOfLoader] = useState(false);

  function handleEmail(evt) {
    setEmail(evt.target.value);
  }

  function handePassword(evt) {
    setShowError_password(false);
    setPassword(evt.target.value);
  }

  function handleFullName(evt) {
    setShowError_name_vide(false);
    setFullname(evt.target.value);
  }

  async function create() {
    if (fullName.length == 0) {
      setShowError_name_vide(true);
    } else if (password.length < 8) {
      setShowError_password(true);
    } else {
      setStateOfLoader(true);
      console.log("showLogin", email, password, fullName);
      let data = {
        username: fullName,
        email: email,
        password: password,
        timezone: momentTimeZone.tz.guess(),
      };
      let res = await create_User(data);
      console.log("reponse register", res);
      setStateOfLoader(false);
      if (res.data && res.data.success) {
        setUserObject(res.data.data);
        history.push("/user/me/valid_email");
      } else if (
        res.data &&
        res.data.success == false &&
        res.data.status == 208
      ) {
        setShowError(true);
        console.log("showjjjjjjjLogin", res);
      }
    }
  }

  useEffect(() => {
    console.log("useEffect", props.location);

    // effect;
    // return () => {
    //   cleanup;
    // };
  }, []);

  function handleEmail(evt) {
    setShowError(false);
    if (evt.target) {
      setEmail(evt.target.value.trim());
    }
  }

  const keyEnter = ({ key }) => {
    console.log("Evt", key);
    if (key == "Enter") {
      create();
    } else return;
  };

  eventHandler("keydown", keyEnter);

  //Formulaire Pour Register

  return (
    <>
      <Header />
      <div className="d-flex align-items-center">
        <div className="container" style={{ width: "50vw" }}>
          <div className="txt-header">
            <img src={logo} className="icon-rounded-logo" />
            <span className="header-txt-title-register">
              {t("register.messageHeader")}
            </span>
          </div>

          <div className="form-group">
            <label className="txt-email-enter">
              {t("register.enter_email")}
            </label>
            <input
              type="text"
              className="input-control"
              placeholder="adresse email"
              id="0.1200959747231034"
              value={email}
              disabled={true}
              onChange={handleEmail}
            />
          </div>
          <div className="form-group">
            <label className="txt-email-enter">
              {t("register.enter_name")}
            </label>
            <input
              type="text"
              className="input-control"
              placeholder="Jean Dupont"
              id="0.125048511147231034"
              value={fullName}
              onChange={handleFullName}
            />
          </div>
          <span
            style={{
              color: "red",
              fontSize: "small",
              marginTop: "2px",
              display: showError ? "inline" : "none",
            }}
          >
            {t("register.error_name_use")}
          </span>
          <span
            style={{
              color: "red",
              fontSize: "small",
              display: showError_name_vide ? "inline" : "none",
            }}
          >
            {t("register.error_name_obligatoire")}
          </span>

          <div className="form-group">
            <label className="txt-email-enter">{t("register.password")}</label>
            <input
              type="password"
              className="input-control"
              placeholder={t("register.password_placeholder")}
              id="0.12504859747112231034"
              value={password}
              onChange={handePassword}
            />
          </div>
          <span
            style={{
              color: "red",
              fontSize: "small",
              display: showError_password ? "inline" : "none",
            }}
          >
            {t("register.error_password")}
          </span>

          <div
            className="btn-commencer-register"
            style={{ pointerEvents: stateOfLoader ? "none" : "auto" }}
          >
            <button className="primary-btn mr-2" onClick={create}>
              <span
                className={
                  "spinner-border spinner-border-sm mr-2 " +
                  (!stateOfLoader ? "d-none" : "")
                }
                role="status"
                aria-hidden="true"
              ></span>
              {t("continuer")}
            </button>
          </div>
          <span
            style={{
              fontSize: "small",
              color: "gray",
              fontFamily: "12vw, Proxima Nova",
            }}
          >
            {t("register.cgu")}
          </span>
        </div>
      </div>
    </>
  );
}
